import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Classroom } from './classroom';
import { AppConfigService } from './app-config.service';
import { OverwrittenDay } from './model/overwritten-day';
import { ClassroomSchedule } from './model/classroom-schedule';
import { ScheduleEntry } from './schedule-entry';
import { UserAvailability } from './model/user-availability';
import { OverwrittenDaysPutRequest } from './model/requests/overwritten-days-put-request';
import { UserAvailabilityPutRequest } from './model/requests/user-availability-put-request';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class ClassService {

  private baseUrl: string;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this.baseUrl = `${appConfig.getConfig().apiUrl}/classes`;
  }

  // GET ALL CLASSROOMS

  findAllClasses(): Observable<Classroom[]> {
    return this.http.get<Classroom[]>(`${this.baseUrl}`);
  }

  // GET WEEKVIEW BY CLASS NAME

  findWeekviewByClassname(classname: string): Observable<ClassroomSchedule> {
    return this.http.get<ClassroomSchedule>(`${this.baseUrl}/${classname}/weekview`);
  }

  // ADD CLASSROOM

  saveClassroom(classroom: object): Observable<Classroom> {
    return this.http.post<Classroom>(`${this.baseUrl}`, classroom);
  }

  // GET CLASSROOM BY ID

  findClassroomById(id: number): Observable<Classroom> {
    return this.http.get<Classroom>(`${this.baseUrl}/${id}`);
  }

  // GET CLASSROOM BY CLASSNAME

  findClassroomByName(classname: string) {
    return this.http.get<Classroom>(`${this.baseUrl}/${classname}`);
  }

  // EDIT CLASSROOM

  editClassroom(classroom: Classroom): Observable<Classroom> {
    return this.http.patch<Classroom>(`${this.baseUrl}`, classroom);
  }

  // SWAP STUDENTS IN WEEKVIEW

  swapStudents(classroomName: string, scheduleEntries: any): Observable<object> {
    return this.http.patch<object>(`${this.baseUrl}/${classroomName}/swapStudents`, scheduleEntries);
  }

  // REMOVE CLASSROOM

  removeClassroom(id: number): Observable<object> {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }

  // DAY OVERWRITING API CALLS

  getOverwrittenDays(classroomName: string, start: string, end: string = start): Observable<OverwrittenDay[]> {
    let httpParams = new HttpParams().set('start', start).set('end', end);
    return this.http.get<OverwrittenDay[]>(`${this.baseUrl}/${classroomName}/overwrittenDays`, {params: httpParams});
  }

  editOverwrittenDays(classroomName: string, overwrittenDaysPutRequest: OverwrittenDaysPutRequest) {
    return this.http.put<OverwrittenDay | OverwrittenDay[]>(
      `${this.baseUrl}/${classroomName}/overwrittenDays`,
      overwrittenDaysPutRequest
    );
  }

  removeOverwrittenDaysById(classroomName: string, id: number) {
    const httpParams = new HttpParams().set('id', id.toString());
    return this.http.delete(`${this.baseUrl}/${classroomName}/overwrittenDays`, {params: httpParams});
  }

  removeOverwrittenDaysByDateRange(classroomName: string, date: string, until?: string) {
    const httpParams = {
      date: date,
      ...(until ? {until: until} : {})
    }

    return this.http.delete(`${this.baseUrl}/${classroomName}/overwrittenDays`, {params: httpParams});
  }

  // USER AVAILABILITY API CALLS

  editUserAvailability(classname: string, userAvailabilityPutRequest: UserAvailabilityPutRequest) {
    return this.http.put<UserAvailability | UserAvailability[]>(
      `${this.baseUrl}/${classname}/availability`,
      userAvailabilityPutRequest
    );
  }

  removeUserAvailabilityById(classname: string, id: number) {
    const httpParams = new HttpParams().set('id', id.toString());
    return this.http.delete(`${this.baseUrl}/${classname}/availability`, {params: httpParams})
  }

  removeUserAvailabilityByDateRange(classname: string, userId: number, date: string, until?: string) {
    const httpParams = {
      userId: userId.toString(),
      date: date,
      ...(until ? {until: until} : {})
    };

    return this.http.delete(`${this.baseUrl}/${classname}/availability`, {params: httpParams})
  }

  //RESCHEDULE
  removeSchedule(date: string, classroom: string): Observable<object> {
    const httpParams = new HttpParams().set('date', date);
    return this.http.delete(`${this.baseUrl}/${classroom}/removeSchedule`, {params: httpParams});
  }

  //ADD ENTRY TO WEEKVIEW
  putNewScheduleEntry(classroom: string, userId: number, date: string): Observable<object>  {
    const body = {'userId': userId, 'date': date}
    return this.http.put(`${this.baseUrl}/${classroom}/weekview`, body);
  }

  //REMOVE ENTRY FROM WEEKVIEW
  deleteScheduleEntry(scheduleEntry: ScheduleEntry){
    const httpParams = new HttpParams().set('id', scheduleEntry.id.toString());
    return this.http.delete(`${this.baseUrl}/${scheduleEntry.classname}/weekview`, {params: httpParams});
  }

}
