import { Component, OnInit } from '@angular/core';
import { Classroom } from '../classroom';
import { HttpErrorResponse } from '@angular/common/http';
import { UserResourceService } from '../user-resource.service';
import { HelpWindowComponent } from '../help-window/help-window.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-class-room-overview',
  templateUrl: './class-room-overview.component.html',
  styleUrls: ['./class-room-overview.component.css']
})
export class ClassRoomOverviewComponent implements OnInit {
  dataLoaded: boolean = false;
  classroomlist: any;
  displayedColumns = ['class_name', 'teacher_name', 'size', 'edit'];

  constructor(private resourceService: UserResourceService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.resourceService.getAllClassrooms().subscribe((classroomlist: Classroom[]) => {
      this.classroomlist = classroomlist;
      this.dataLoaded = true;
    },
    (err: HttpErrorResponse) => {
      console.log(`${err.status} ${err.message}`);
    });
  }

  getHelp(components: String[]): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "450px";
    dialogConfig.data = {'components': components};
    this.dialog.open(HelpWindowComponent, dialogConfig);
  }


}
