import { Injectable } from '@angular/core';
import { User } from './user';
import { Observable, pipe, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Day } from './day';
import { Classroom } from './classroom';
import { UserService } from './user.service';
import { ClassService } from './class.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserResourceService {

  private users: User[] = null;
  private classrooms: Classroom[] = null;
  private weekOverview: Day[] = null;

  constructor(
    private userService: UserService,
    private classService: ClassService
  ) { }

  updateUsers(): Observable<User[]> {
    return this.userService.findAllUsers().pipe(
      tap((users: User[]) => { console.log('Updating users'); this.users = users; })
    );
  }

  updateClassrooms(): Observable<Classroom[]> {
    return this.classService.findAllClasses().pipe(
      tap((classrooms: Classroom[]) => { console.log('Updating classrooms'); this.classrooms = classrooms; })
    );
  }

  getAllUsers(): Observable<User[]> {
    //return this.users ? of(this.users) : this.updateUsers();
    return this.updateUsers();
  }

  getAllClassrooms(): Observable<Classroom[]> {
    //return this.classrooms ? of(this.classrooms) : this.updateClassrooms();
    return this.updateClassrooms();
  }

}
