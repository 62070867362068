import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors } from'@angular/forms';
import { ClassService } from '../class.service';
import { MatTable } from '@angular/material/table';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ScheduleEntry } from '../schedule-entry';
import { map, filter, mergeMap, tap, finalize, delay } from 'rxjs/operators';
import { User } from '../user';
import { Day } from '../day';
import { Classroom } from '../classroom';
import { ScheduleService } from '../schedule.service';

@Component({
  selector: 'app-edit-weekview',
  templateUrl: './edit-weekview.component.html',
  styleUrls: ['./edit-weekview.component.css']
})
export class EditWeekviewComponent implements OnInit {

  dataLoaded: boolean = false;
  day = this.data.day;
  classroom: Classroom;
  schedule: ScheduleEntry[];
  users: User[];
  freeSpace: boolean;

  submitted: boolean = false;
  message: string;

  unselectedStudents: User[];
  selectedStudents: User[];

  emptyUser: User = {
    id:            0,
    username:      'empty@mail.com',
    name:          'veld leeg houden',
    initials:      'VLG',
    classroomName: this.data.day.userButtons[0].user.classroomName,
    teacher:       false,
    startDate:     'NULL',
    endDate:       'NULL',
  }

  labelCol: string;
  inputCol: string;
  dateCol: string;
  submitCol: string;

  editScheduleMeta: any;
  editScheduleForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditWeekviewComponent>,
    private formBuilder: FormBuilder,
    private classService: ClassService,
    private toastr: ToastrService
  ) {}

  protected initTemplateData(): void {
    this.editScheduleMeta = [
      {controlName: 'oldStudent', id: 'oldStudent', element: 'select', labelText: 'Student uitroosteren:'},
      {controlName: 'newStudent', id: 'newStudent', element: 'select', labelText: 'Student inroosteren:'},
    ];

    this.labelCol = 'col-md-4';
    this.inputCol = 'col-md-7';
    this.dateCol = 'col-md-3';
    this.submitCol = 'col-md-3';
  }

   protected initForm(): void {


    const availableValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
      const oldStudentId = Number(control.get('oldStudent').value);
      const newStudentId = Number(control.get('newStudent').value);
      return oldStudentId === newStudentId || oldStudentId + newStudentId <= 0 ? { available: true } : null;
    };




    this.editScheduleForm = this.formBuilder.group({
      oldStudent: ['0', [Validators.required]],
      newStudent: ['0', [Validators.required]],
    }, {validators: availableValidator});
  }

  ngOnInit() {
    this.initConstants();
    this.loadStudents();
    this.initTemplateData();
    this.initForm();
  }

  getScheduleEntry(studentId: number): ScheduleEntry {
    let scheduleEntry = this.schedule.filter(a => a.userId == studentId);
    return scheduleEntry.pop();
  }

  initConstants(){
    this.day = this.data.day;
    this.classroom = this.data.classroom;
    this.schedule = this.data.schedule;
    this.users = this.data.classroom.students;
    this.freeSpace = this.data.classroom.size > this.day.userButtons;

  }

  loadStudents(){
    let selectedStudentIds = this.day.userButtons.map((userButton) => userButton.user.id);
    this.selectedStudents = this.data.classroom.students.filter((user)=>selectedStudentIds.includes(user.id));
    this.unselectedStudents = this.data.classroom.students.filter((user)=>!selectedStudentIds.includes(user.id));
  }

  onSubmit(){
    this.submitted = true;
    if (this.editScheduleForm.valid) {
      this.editSchedule(this.day);
    }
  }



  resetStudent() {
    this.editScheduleForm.get('newStudent').setValue('0');
  }

  editSchedule(day: Day): void {
    const oldStudentId = this.editScheduleForm.get('oldStudent').value;
    const newStudentId = this.editScheduleForm.get('newStudent').value;
    const oldStudent = this.users.filter((user)=> user.id.toString() === oldStudentId).pop();
    const newStudent = this.users.filter((user)=> user.id.toString() === newStudentId).pop();

    if (oldStudent?.id > 0 && newStudent?.id > 0) {
      let entry = this.getScheduleEntry(oldStudent.id);
      this.deleteAndAdd(entry, oldStudent, newStudent);
    }
    else {
      if (oldStudent?.id > 0) {
        let entry = this.getScheduleEntry(oldStudent.id);
        this.deleteSchedule(entry, oldStudent);
      }
      if (newStudent?.id > 0) {
        this.addScheduleEntry(day, this.data.classroom.classname, newStudent);
      }
    }
  }

  deleteAndAdd(entry: ScheduleEntry, oldStudent: User, newStudent: User) {
    this.dialogRef.disableClose = true;
    this.classService.deleteScheduleEntry(entry)
    .pipe(
      tap(() => {this.toastr.success('Student ' + oldStudent.name + ' succesvol verwijderd');}),
      mergeMap(() => this.classService.putNewScheduleEntry(entry.classname, newStudent.id, this.day.date)),
      finalize(() => {this.dialogRef.disableClose = false})
    )
    .subscribe(
      () => {
        this.toastr.success('Student ' + newStudent.name + ' succesvol toegevoegd');
        this.dialogRef.close();
      },
      (err: any) => {
        if (err instanceof HttpErrorResponse && err.status == 404) {
          this.message = 'Klas niet gevonden.';
        }
        else {
          this.message = 'Er is een technische fout opgetreden, probeer het later nog eens';
          console.log(err);
        }
      }
    );
  }

  addScheduleEntry(day: Day, classname: string, newStudent: User): void {
    this.dialogRef.disableClose = true;
    this.classService.putNewScheduleEntry(classname, newStudent.id, day.date)
    .pipe(
      finalize(() => {this.dialogRef.disableClose = false})
    )
    .subscribe(
      () => {
        this.toastr.success('Student ' + newStudent.name + ' succesvol toegevoegd');
        this.dialogRef.close();
      },
      (err: any) => {
        if (err instanceof HttpErrorResponse && err.status == 404) {
          this.message = 'Klas niet gevonden.';
        }
        else {
          this.message = 'Er is een technische fout opgetreden, probeer het later nog eens';
          console.log(err);
        }
      }
    );
  }

  deleteSchedule(entry: ScheduleEntry, oldStudent: User) {
    this.dialogRef.disableClose = true;
    this.classService.deleteScheduleEntry(entry)
    .pipe(
      finalize(() => {this.dialogRef.disableClose = false})
    )
    .subscribe(
      () => {
        this.toastr.success('Student '+ oldStudent.name + ' succesvol verwijderd');
        this.dialogRef.close();
      },
      (err: any) => {
        if (err instanceof HttpErrorResponse && err.status == 404) {
          this.message = 'Klas niet gevonden.';
        }
        else {
          this.message = 'Er is een technische fout opgetreden, probeer het later nog eens';
          console.log(err);
        }
      }
    );
  }

}
