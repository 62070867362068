import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { User } from '../user';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  currentUser: User;

  constructor(private auth: AuthService) { }

  ngOnInit(): void {
    this.auth.getCurrentUser()
    .pipe(
      tap((user: User) => {this.currentUser = user;})
    ).subscribe();
  }

  logout(): void {
    this.auth.logout();
  }

}
