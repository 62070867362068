import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { User } from './user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.auth.setSessionToken();
  }
}
