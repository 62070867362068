import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { User } from '../user';
import { Classroom } from '../classroom';
import { ClassService } from '../class.service';
import { map, tap, filter, ignoreElements, mergeMap } from 'rxjs/operators';
import { Observable, merge, of } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HelpWindowComponent } from '../help-window/help-window.component';
import { ScheduleOverwriteService } from '../services/schedule-overwrite.service';

@Component({
  selector: 'app-add-student-view',
  templateUrl: './add-student-view.component.html',
  styleUrls: ['./add-student-view.component.css']
})
export class AddStudentViewComponent implements OnInit {

  meta: any;

  labelCol: string;
  inputCol: string;

  studentForm: FormGroup;

  classroomName: string;
  studentId: number;
  student: User;

  classrooms: Classroom[];

  submitted = false;

  message: string;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private classService: ClassService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    public  scheduleOverwriteService: ScheduleOverwriteService,
  ) { }

  protected initTemplateData(): void {
    const weekdayControls = [
      {controlName: 'monday',    element: 'input', inputType: 'checkbox', labelText: 'Ma'},
      {controlName: 'tuesday',   element: 'input', inputType: 'checkbox', labelText: 'Di'},
      {controlName: 'wednesday', element: 'input', inputType: 'checkbox', labelText: 'Wo'},
      {controlName: 'thursday',  element: 'input', inputType: 'checkbox', labelText: 'Do'},
      {controlName: 'friday',    element: 'input', inputType: 'checkbox', labelText: 'Vr'},
    ];

    this.meta = [
      {controlName: 'username',        element: 'input', inputType: 'text',      labelText: 'Email:'},
      {controlName: 'name',            element: 'input', inputType: 'text',      labelText: 'Naam:'},
      {controlName: 'initials',        element: 'input', inputType: 'text',      labelText: 'Initialen:'},
      {controlName: 'password',        element: 'input', inputType: 'password',  labelText: 'Wachtwoord:'},
      {controlName: 'confirmPassword', element: 'input', inputType: 'password',  labelText: 'Herhaal Wachtwoord:'},
      {controlName: 'classroomName',   element: 'select',                        labelText: 'Klas:'},
      {controlName: 'plannerWeight',   element: 'input', inputType: 'number',    labelText: 'Planfrequentie:'},
      {controlName: 'startDate',       element: 'input', inputType: 'date',      labelText: 'Startdatum:'},
      {controlName: 'endDate',         element: 'input', inputType: 'date',      labelText: 'Einddatum:'},
      {
        controlName: 'enabledWeekdaysOnSite',
        element: 'div',
        inputType: 'formGroup',
        labelText: 'Dagen beschikbaar: ',
        nested: weekdayControls
      }
    ];

    this.labelCol = 'col-md-4';
    this.inputCol = 'col-md-7';
  }

  protected initForm(): void {
    const dateValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
      const startDate = formGroup.get('startDate');
      const endDate = formGroup.get('endDate');

      if(startDate && endDate){
        return Date.parse(startDate.value) > Date.parse(endDate.value) ? {date: true} : null;
      }
      else{
        return null;
      }
    };

    const confirmPasswordValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
      const pass = formGroup.get('password').value;
      const confirm = formGroup.get('confirmPassword').value;

      return pass == confirm ? null : {passwords: true};
    };

    const anyWeekdayEnabledValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
      const enabled: boolean[] = Object.values(formGroup.value);
      return enabled.reduce((a,b) => a || b) ? null : {anyWeekdayEnabled: true};
    };

    const passwordValidators = this.studentId ? [] : [Validators.required];
    this.studentForm = this.formBuilder.group({
      username:        ['', [Validators.required, Validators.email]],
      name:            ['', [Validators.required, Validators.pattern('[A-Z][a-z]+.*')]],
      initials:        ['', [Validators.required, Validators.pattern('[A-Z]([a-z]*[A-Z])*')]],
      password:        ['', passwordValidators],
      confirmPassword: ['', passwordValidators],
      classroomName:   ['', Validators.required],
      plannerWeight:   ['', [Validators.min(1), Validators.max(5)]],
      startDate:       [''],
      endDate:         [''],
      enabledWeekdaysOnSite: this.formBuilder.group({
        monday:    [true],
        tuesday:   [true],
        wednesday: [true],
        thursday:  [true],
        friday:    [true],
        saturday:  [false],
        sunday:    [false]
      },{validators:[anyWeekdayEnabledValidator]}),
      enabledWeekdaysOnCall: this.formBuilder.group({
        monday:    [true],
        tuesday:   [true],
        wednesday: [true],
        thursday:  [true],
        friday:    [true],
        saturday:  [false],
        sunday:    [false]
      },{validators:[anyWeekdayEnabledValidator]})
    }, {validators: [confirmPasswordValidator, dateValidator]});
  }

  private processClassrooms(): Observable<never> {
    return this.classService.findAllClasses().pipe(
      tap(classroomlist => { this.classrooms = classroomlist }),
      ignoreElements()
    );
  }

  private insertStudentInForm(): void {
    this.studentForm.controls.username.setValue(this.student.username);
    this.studentForm.controls.name.setValue(this.student.name);
    this.studentForm.controls.initials.setValue(this.student.initials);
    this.studentForm.controls.plannerWeight.setValue(this.student.plannerWeight);
    this.studentForm.controls.classroomName.setValue(this.student.classroomName);
    this.studentForm.controls.startDate.setValue(this.student.startDate);
    this.studentForm.controls.endDate.setValue(this.student.endDate);
    this.studentForm.controls.enabledWeekdaysOnSite.setValue(this.student.enabledWeekdaysOnSite);
    this.studentForm.controls.enabledWeekdaysOnCall.setValue(this.student.enabledWeekdaysOnCall);
  }

  resetForm(): void {
    this.submitted = false;
    this.studentForm.reset();
  }

  populateForm(): void {
    if (this.student && !this.student.teacher) {
      this.insertStudentInForm();
    }
  }

  private processUser(): Observable<never> {
    if (this.studentId <= 0) {
      this.studentForm.controls.password.setValidators(Validators.required);
      this.studentForm.controls.confirmPassword.setValidators(Validators.required);
    }

    return of(this.studentId)
    .pipe(
      filter(studentId => studentId > 0),
      mergeMap(studentId => this.userService.findUserById(studentId)),
      tap(user => {this.student = user; this.populateForm()}),
      ignoreElements()
    )
  }

  generateInitialsFromName(name: string) {
    const isUpperCase = (str: string) => /^[A-Z]$/.test(str);
    return name.split('').filter(isUpperCase).join('');
  }

  onBlur(controlName: string) {
    if (controlName === 'name' && !this.studentForm.get('name').errors && !this.studentForm.get('initials').value) {
      const initials = this.generateInitialsFromName(this.studentForm.get('name').value);
      this.studentForm.controls.initials.setValue(initials);
    }
  }

  ngOnInit(): void {

    this.initTemplateData();

    this.route.paramMap.pipe(
      map((paramMap: ParamMap) => {
        this.classroomName = paramMap.get('classroomName');
        this.studentId = Number(paramMap.get('id'));

        this.initForm();

        if (this.classroomName)
          this.studentForm.controls.classroomName.setValue(this.classroomName);
      }),
      mergeMap(() => merge(this.processUser(),this.processClassrooms()))
    )
    .subscribe({
      error: (err: HttpErrorResponse) => {
        console.log(`HTTP REQUEST ERROR ${err.status} ${err.statusText}`);
        this.router.navigateByUrl('/');
      }
    });
  }

  protected buildUser() {
    const formValues = {...this.studentForm.value};
    delete formValues.confirmPassword;

    const newUser: User = {...formValues};
    newUser.teacher = false;

    if (newUser.password == '')
      delete newUser.password;

    if (this.student)
      newUser.id = this.student.id;

    return newUser;
  }

  protected addUser() {
    const newUser = this.buildUser()
    this.userService.saveUser(newUser).subscribe(
      (user: User) => {
        this.router.navigateByUrl(`/user/edit-classroom/${user.classroomName}`);
      },
      (err: HttpErrorResponse) => {
        if (err.status == 409) {
          this.message = 'E-mail en/of initialen is niet uniek';
        }
        else {
          this.message = 'Er is een technische fout opgetreden, probeer het later nog eens';
          console.log(`HTTP REQUEST ERROR ${err.status} ${err.statusText}`);
        }
      }
    );
  }

  protected editUser() {
    const newUser = this.buildUser()
    this.userService.updateUser(newUser).subscribe(
      (user: User) => {
        this.router.navigateByUrl(`/user/edit-classroom/${user.classroomName}`);
      },
      (err: HttpErrorResponse) => {
        if (err.status == 404) {
          this.message = 'Er is geen gebruiker gevonden met dit id';
        }
        else if (err.status == 405) {
          this.message = 'Deze gebruiker is een docent';
        }
        else {
          this.message = 'Er is een technische fout opgetreden, probeer het later nog eens';
          console.log(`HTTP REQUEST ERROR ${err.status} ${err.statusText}`);
        }
      }
    );
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.studentForm.valid) {
      if (this.student){
        this.editUser();
      }
      else {
        this.addUser();
      }
    }
  }

  getHelp(components: String[]): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "450px";
    dialogConfig.height = "600px";
    dialogConfig.data = {'components': components};
    this.dialog.open(HelpWindowComponent, dialogConfig);
  }

}
