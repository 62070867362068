import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors } from'@angular/forms';
import { User } from '../user';
import { Day } from '../day';
import { ClassService } from '../class.service';
import { Classroom } from '../classroom';
import { ScheduleService } from '../schedule.service';
import { tap, map, concatAll, mergeMap, first, ignoreElements } from 'rxjs/operators';
import { ActivatedRoute, ParamMap} from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { CdkDragDrop, CdkDragEnter } from '@angular/cdk/drag-drop';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditWeekviewComponent } from '../edit-weekview/edit-weekview.component';
import { concat, EMPTY, from, defer } from 'rxjs';
import { ClassroomSchedule } from '../model/classroom-schedule';
import { DropListType } from './model/drop-list-type.enum';
import { DropListStruct } from './model/drop-list-struct';
import { ScheduleEntry } from '../model/schedule-entry';
import { UserButton } from './model/user-button';
import { HelpWindowComponent } from '../help-window/help-window.component';
import { UserService } from '../user.service';
import { Availability } from '../model/availability.enum';

@Component({
  selector: 'app-weekview',
  templateUrl: './weekview.component.html',
  styleUrls: ['./weekview.component.css']
})
export class WeekviewComponent implements OnInit {
  availability = Availability;

  dataLoaded: boolean = false;

  currentUser: User;

  draggedOverUserButton: UserButton;
  isPointerOverDropList: boolean;

  dropListType = DropListType;

  location: string;
  classroom: Classroom;

  labelCol: string;
  inputCol: string;
  dateCol: string;
  submitCol: string;

  submitted: boolean = false;

  rescheduleMeta: any;
  rescheduleForm: FormGroup;

  days: Day[];

  weekSchedule: ScheduleEntry[];
  message: string;

  displayedColumns = ['date', 'teacherPresence', 'students'];

  constructor(
    private classService: ClassService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private userService: UserService,
    private scheduleService: ScheduleService,
    private auth: AuthService,
    private toastr: ToastrService,
    private route: ActivatedRoute) {}

  convertScheduleToDays(schedule: any, teacherPresence: {[date: string]: Availability}): Day[] {
    const days: Day[] = [];

    const getStudentByUserId: (userId: number) => User = (userId) => {
      return this.classroom.students.find((student: User) => student.id === userId);
    };

    const setDataIntoDays = (date: string, userButton: UserButton) => {
      const day: Day = days.find((day: Day) => day.date === date);
      if (day !== undefined)
        day.userButtons.push(userButton);
      else
        days.push({
          date: date,
          teacherPresence: teacherPresence[date],
          userButtons: [userButton],
          addButton: {color: 'basic'}
        });
    };

    schedule.forEach(element => {
      const presentColor = element.userId === this.currentUser.id ? 'accent' : 'primary';
      const absentColor = element.userId === this.currentUser.id ? 'absent' : 'danger';
      const color: string = element.present ? presentColor : absentColor;
      setDataIntoDays(element.date, {user: getStudentByUserId(element.userId), present: element.present, color: color});
    });

    days.sort((a,b) => Date.parse(a.date) - Date.parse(b.date));
    days.forEach((day: Day) => { day.userButtons.sort((a, b) => a.user.initials.localeCompare(b.user.initials)) });

    return days;
  }

  getLocation() {
    return this.route.paramMap.pipe(
      map((paramMap: ParamMap) => paramMap.get('classname')),
      map((location: string) => location ? location : this.currentUser.classroomName),
      tap((location: string) => {
        this.location = location;
      })
    );
  }
  protected initTemplateData(): void {
    this.rescheduleMeta = [
      {controlName: 'date', id: 'date', element: 'input',  inputType: 'date', labelText: 'Opnieuw inplannen vanaf dag: '}
    ];

    this.labelCol = 'col-md-4';
    this.inputCol = 'col-md-7';
    this.dateCol = 'col-md-3';
    this.submitCol = 'col-md-3';
  }

  protected initForm(): void {
    const dateValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
      const date = Date.parse(control.value);
      const today = new Date();
      // Day and month overflows are automatically handled
      const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
      return date < tomorrow.getTime() ? {date: true} : null;
    };

    this.rescheduleForm = this.formBuilder.group({
      date:     ['', [Validators.required, dateValidator]],
    });
  }

  processDuplicateInitials(users: User[]) {
    const userMap: Map<string,User[]> = new Map();

    users.forEach((user) => {
      if (userMap.has(user.initials)) {
        userMap.get(user.initials).push(user);
      }
      else {
        userMap.set(user.initials, [user]);
      }
    });

    userMap.forEach(users => {
      if (users.length < 2) {
        return;
      }

      const compareSimpleValues = (a,b) => a < b ? -1 : a > b ? 1 : 0;
      users.sort((a,b) => compareSimpleValues(new Date(a.startDate), new Date(b.startDate)));
      users.forEach((user,index) => {
        user.initials += index + 1;
      });
    });
  }

  getWeekview() {
    const setWeekview = this.classService.findWeekviewByClassname(this.location)
    .pipe(
      tap((weekview: ClassroomSchedule) => {
        this.classroom = weekview.classroom;
        this.processDuplicateInitials(this.classroom.students);
        this.weekSchedule = weekview.schedule;
        this.days = this.convertScheduleToDays(weekview.schedule, weekview.teacherPresence);
      }),
      ignoreElements()
    );

    const setUserProfilePictures = defer(() => from(this.classroom.students))
    .pipe(
      mergeMap(user => {
        return this.userService.findProfilePicture(user.id)
        .pipe(tap(profilePicture => {user.profilePicture = profilePicture}));
      }),
      ignoreElements()
    );

    return concat(setWeekview, setUserProfilePictures);
  }

  ngOnInit(): void {
    this.auth.getCurrentUser()
    .pipe(
      tap((user: User) => {
        this.currentUser = user;
        this.dataLoaded = true;
      }),
      mergeMap(() => this.getLocation()),
      mergeMap((location: string) => this.getWeekview())
    )
    .subscribe();

    if(this.currentUser.teacher){
      this.initTemplateData();
      this.initForm();
    }
  }

  getUserButtonColor(userButton: UserButton) {
    if (!userButton.user) {
      return 'basic';
    }
    const presentColor = userButton.user?.id === this.currentUser.id ? 'accent' : 'primary';
    const absentColor = userButton.user?.id === this.currentUser.id ? 'absent' : 'danger';
    return userButton.present ? presentColor : absentColor;
  }

  private checkScheduleEntry(date: string, userId: number) {
    return (scheduleEntry: ScheduleEntry) => scheduleEntry.date === date && scheduleEntry.userId === userId;
  }

  onDragDrop(event: CdkDragDrop<DropListStruct>) {
    const previousData: DropListStruct = event.previousContainer.data;
    const currentData: DropListStruct = event.container.data;
    const dragData: UserButton = event.item.data;

    if (currentData.type === DropListType.ScheduledStudent)
      currentData.userButton.color = this.getUserButtonColor(currentData.userButton);
    else if (currentData.type === DropListType.AddStudentButton)
      currentData.day.addButton.color = this.getUserButtonColor(currentData.day.addButton);

    this.draggedOverUserButton = undefined;

    if (!event.isPointerOverContainer || !this.isPointerOverDropList) {
      this.toastr.warning('De muis zat niet boven een verwisselbaar element');
      return;
    }

    if (currentData.type === DropListType.ScheduledStudent && previousData.type === DropListType.ScheduledStudent) {
      this.swapScheduledStudents(currentData, previousData);
    }
    else {
      this.rescheduleStudent(currentData, previousData, dragData);
    }
  }

  swapScheduledStudents(currentData: DropListStruct, previousData: DropListStruct) {
    const previousUserIds: number[] = previousData.day.userButtons.map(userButton => userButton.user.id);
    const currentUserIds: number[] = currentData.day.userButtons.map(userButton => userButton.user.id);

    if (currentUserIds.includes(previousData.userButton.user.id)) {
      this.toastr.warning('De gesleepte student was al voor beide dagen ingepland');
    }
    else if (previousUserIds.includes(currentData.userButton.user.id)) {
      this.toastr.warning('De andere student was al voor beide dagen ingepland');
    }
    else {
      const entryA = this.weekSchedule.find(this.checkScheduleEntry(previousData.day.date, previousData.userButton.user.id));
      const entryB = this.weekSchedule.find(this.checkScheduleEntry(currentData.day.date, currentData.userButton.user.id));

      this.classService.swapStudents(this.location, {entryA: entryA, entryB: entryB})
      .subscribe({
        next: (weekview: ClassroomSchedule) => {
          this.weekSchedule = weekview.schedule;
          this.days = this.convertScheduleToDays(weekview.schedule, weekview.teacherPresence);
          this.toastr.success('Het verwisselen van de studenten is gelukt');
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          this.toastr.error('Vanwege een technische fout kunnen de studenten niet worden verwisseld');
        }
      });
    }
  }

  private deleteScheduleEntry(scheduleEntryData: DropListStruct) {
    const scheduleEntry = this.weekSchedule.find(
      this.checkScheduleEntry(scheduleEntryData.day?.date, scheduleEntryData.userButton?.user.id)
    );
    return !scheduleEntry ? EMPTY : this.classService.deleteScheduleEntry(scheduleEntry)
    .pipe(
      tap(() => {this.toastr.success('Student ' + scheduleEntryData.userButton.user.name + ' succesvol verwijderd')}),
      ignoreElements()
    );
  }

  private addScheduleEntry(scheduleEntryData: DropListStruct, userButton: UserButton) {
    const day = scheduleEntryData.day;
    return !day ? EMPTY : this.classService.putNewScheduleEntry(this.location, userButton.user.id, day.date)
    .pipe(
      tap(() => {this.toastr.success('Student ' + userButton.user.name + ' succesvol toegevoegd')}),
      ignoreElements()
    )
  }

  rescheduleStudent(currentData: DropListStruct, previousData: DropListStruct, userButton: UserButton) {
    const currentUserIds: number[] = currentData.day?.userButtons.map(userButton => userButton.user.id);
    if (currentUserIds?.includes(userButton.user.id)) {
      this.toastr.warning('De ingevoegde student was al voor deze dag ingepland');
    }
    else {
      concat(
        this.deleteScheduleEntry(previousData),
        this.deleteScheduleEntry(currentData),
        this.addScheduleEntry(currentData, userButton),
        this.getWeekview()
      )
      .subscribe({
        error: (err: any) => {
          this.toastr.error('Vanwege technische problemen kan deze handeling niet worden voltooid');
        }
      });
    }
  }

  onDragEnter(event: CdkDragEnter<DropListStruct,UserButton>) {
    const previousData = event.item.dropContainer.data as DropListStruct;
    const currentData = event.container.data;

    const draggedUserButton = event.item.data;
    const draggedOverUserButton = currentData.userButton ? currentData.userButton : currentData.day.addButton;

    const previousUserIds: number[] = previousData.day?.userButtons.map(userButton => userButton.user.id);
    const currentUserIds: number[] = currentData.day?.userButtons.map(userButton => userButton.user.id);

    const previousInCurrentUserIds = currentUserIds?.includes(draggedUserButton.user?.id);
    const currentInPreviousUserIds = previousUserIds?.includes(draggedOverUserButton.user?.id);

    const color = previousInCurrentUserIds || currentInPreviousUserIds ? 'warn' : 'success';
    draggedOverUserButton.color = color;

    this.draggedOverUserButton = {...draggedOverUserButton};
  }

  onMouseEnter(dropListData: DropListStruct) {
    this.isPointerOverDropList = true;
    switch (dropListData.type) {
      case DropListType.AddStudentButton:
        if(this.draggedOverUserButton && !this.draggedOverUserButton.user) {
          dropListData.day.addButton.color = this.draggedOverUserButton.color;
        }
        break;
      case DropListType.ScheduledStudent:
        if(dropListData.userButton.user.id === this.draggedOverUserButton?.user?.id) {
          dropListData.userButton.color = this.draggedOverUserButton.color;
        }
        break;
    }
  }

  onTouchStart(dropListData: DropListStruct) {
    this.onMouseEnter(dropListData);
  }

  onMouseLeave(dropListData: DropListStruct) {
    this.isPointerOverDropList = false;

    switch (dropListData.type) {
      case DropListType.AddStudentButton:
        dropListData.day.addButton.color = this.getUserButtonColor(dropListData.day.addButton);
        break;
      case DropListType.ScheduledStudent:
        dropListData.userButton.color = this.getUserButtonColor(dropListData.userButton);
        break;
    }
  }

  onTouchEnd(dropListData: DropListStruct) {
    this.onMouseLeave(dropListData);
  }

  changePresence(userButton: UserButton, date: Date) {
    if (!this.currentUser?.teacher) {
      return;
    }

    this.scheduleService.updatePresence({
      date:      date,
      classname: this.location,
      userId:    userButton.user.id,
      present:   !userButton.present
    })
    .pipe(
      map((responseObject: object) => this.getWeekview()),
      concatAll()
    )
    .subscribe({
      error: (error) => {
        console.log(error);
        if (error.status === 403) {
          this.toastr.error('Deze gebruiker is niet bevoegd om presentie aan te geven');
        }
        else {
          this.toastr.error('Vanwege een technische fout is het aangeven van presentie niet gelukt');
        }
      }
    });
  }

  onAdd(dateStr: string){
    let selectedDay = this.days.filter((day)=>day.date === dateStr).pop();
    let schedule = this.weekSchedule.filter((entry)=>entry.date === dateStr);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "600px";
    dialogConfig.data = { day: selectedDay,
                          classroom: this.classroom,
                          schedule: schedule};
    this.dialog.open(EditWeekviewComponent, dialogConfig);

    const emitOnFirstClose = this.dialog.afterAllClosed.pipe(first(), ignoreElements());
    concat(emitOnFirstClose, this.getWeekview())
    .subscribe()
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.rescheduleForm.valid) {
      const date = this.rescheduleForm.get('date').value;
      this.refreshSchedule(date);
    }
  }


  refreshSchedule(date: string): void {
    this.classService.removeSchedule(date, this.location)
    .pipe(
      mergeMap(() => this.getWeekview())
    )
    .subscribe(
      () => {
        this.toastr.success('Opnieuw plannen is gelukt');
        this.submitted = false;
      },
      (err: HttpErrorResponse) => {
        if (err.status == 404) {
          this.message = 'Klas niet gevonden.';
        }
        else {
          this.message = 'Er is een technische fout opgetreden, probeer het later nog eens';
          console.log(`HTTP REQUEST ERROR ${err.status} ${err.statusText}`);
        }
      }
    );
  }

  getHelp(components: String[]): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "450px";
    dialogConfig.data = {'components': components};
    this.dialog.open(HelpWindowComponent, dialogConfig);
  }
}
