<div class="my-1 d-inline-block w-100">
  <h1 class="float-left col-10">Gebruikers:</h1>
  <button mat-mini-fab class="float-right col-10" (click)="getHelp(['useroverview'])">
    <span class="material-icons">help_outline</span>
  </button>
</div>

<div>
  <h4>Selecties:</h4>
  <mat-button-toggle-group name="classroomName" class="btn-group-vertical" aria-label="studentTypes" #group="matButtonToggleGroup" (change)=applyFilter($event)>
    <mat-button-toggle mat-raised-button value="">Alle gebruikers</mat-button-toggle>
    <mat-button-toggle mat-raised-button value="new_students">Huidige Studenten</mat-button-toggle>
    <mat-button-toggle mat-raised-button value="old_students">Oud Studenten</mat-button-toggle>
  <!--  <mat-button-toggle mat-raised-button value="">Alle klassen</mat-button-toggle>
    <mat-button-toggle mat-raised-button *ngFor="let element of classroomList" value="{{element}}">{{element}}</mat-button-toggle>-->
  </mat-button-toggle-group>


</div>

  <mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">

    <!-- Naam Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Naam </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="d-none d-md-block">{{ element.name }}</div>
        <div title="{{element.name}}" class="d-md-none">{{ element.initials }}</div>
      </mat-cell>
    </ng-container>

    <!--Classroom Column -->
    <ng-container matColumnDef="classroomName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Klas </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.classroomName}} </mat-cell>
    </ng-container>

    <!-- Einddatum Column -->
    <ng-container matColumnDef="end_date">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Einddatum </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="d-none d-md-block">{{ element.endDate | date:'dd-MM-yyyy'}}</div>
        <div title="{{element.name}}" class="d-md-none">{{ element.endDate | date:'dd/MM/yy'}}</div></mat-cell>
    </ng-container>

    <!-- Edit Column -->
    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button *ngIf="!element?.teacher" type="button" class="d-none d-md-block" mat-stroked-button routerLink="{{'/user/edit-student/' + element.id}}">aanpassen</button>
        <button *ngIf="!element?.teacher" type="button" class="d-md-none" mat-stroked-button routerLink="{{'/user/edit-student/' + element.id}}"><mat-icon>edit</mat-icon></button>
        <button mat-stroked-button disabled *ngIf="element?.teacher">Docent</button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
