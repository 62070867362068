import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { User } from '../user';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(
    private auth: AuthService, 
    private router: Router,) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {
      
      return this.auth.getCurrentUser().pipe(
        map((user: User) => {
          if(!this.auth.isAuthenticated()) {
            return true;
          }
          else if (this.auth.isTeacher()) {
            return this.router.parseUrl('/user/classroomoverview');
          }
          else {
            return this.router.parseUrl('/user/weekview');
          }
        })
      );
  }
  
}
