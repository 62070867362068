import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class AppConfigService {

  private appConfig: any;
  private http: HttpClient;

  constructor(private handler: HttpBackend) {
    this.http = new HttpClient(handler);
   }

  async loadAppConfig(): Promise<void> {
    try {
      const config = await this.http.get('assets/config/appConfig.json').toPromise();
      this.appConfig = environment.production ? config : environment;
    }
    catch (err) {
      console.log(err);
      this.appConfig = environment;
    }

    console.log(`API URL set to ${this.appConfig.apiUrl}`);
  }

  getConfig() {
    return this.appConfig;
  }
}
