<div class="my-1 d-inline-block w-100">
  <h1 class="float-left col-10">Weekoverzicht {{location}}</h1>  <button mat-mini-fab class="float-right col-2" *ngIf=currentUser?.teacher (click)="getHelp(['weekview'])"><span class="material-icons">help_outline</span></button>
</div>

<div *ngIf="days" cdkDropListGroup [cdkDropListGroupDisabled]="!currentUser?.teacher">


  <mat-table [dataSource]="days" class="mat-elevation-z8 overflow-auto">
    <!-- Datum Column -->
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef> Datum </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.date | date: 'EE dd/MM'}}  </mat-cell>
    </ng-container>

    <!-- Teacher presence column -->
    <ng-container matColumnDef="teacherPresence">
      <mat-header-cell *matHeaderCellDef>Docent</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <label *ngIf="element.teacherPresence === availability.Physical" title="Aanwezig" class="material-icons">check_circle</label>
        <label *ngIf="element.teacherPresence === availability.Digital" title="Online" class="material-icons">wifi</label>
        <label *ngIf="element.teacherPresence === availability.Unavailable" title="Afwezig" class="material-icons">cancel</label>
      </mat-cell>
    </ng-container>

    <!-- Studenten Column -->
    <ng-container matColumnDef="students">
      <mat-header-cell *matHeaderCellDef> Studenten: </mat-header-cell>
      <mat-cell *matCellDef="let element">


        <ng-container *ngFor="let userButton of element.userButtons" >
          <div *ngIf="{type: dropListType.ScheduledStudent, day: element, userButton: userButton} as dropListData"
               cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="dropListData"
               (cdkDropListDropped)="onDragDrop($event)" (cdkDropListEntered)="onDragEnter($event)"
               class="mr-1">
            <button cdkDrag [cdkDragData]="userButton" mat-fab  [cdkDragStartDelay]="500"
                    (click)="changePresence(userButton, element.date)"
                    [color]="userButton.color" (mouseenter)="onMouseEnter(dropListData)" (mouseleave)="onMouseLeave(dropListData)"
                    (touchstart)="onTouchStart(dropListData)" (touchend)="onTouchEnd(dropListData)"
                    placement="top" ngbTooltip="{{ userButton.user.name }}">

              <span *cdkDragPlaceholder></span>
              <img class="profile-picture" *ngIf="userButton.user.profilePicture"
                   src="data:image/png;base64,{{userButton.user.profilePicture.bytes}}">
              <div *ngIf="!userButton.user.profilePicture">{{userButton.user.initials}}</div>

            </button>
          </div>
        </ng-container>


        <div *ngIf="currentUser?.teacher && {type: dropListType.AddStudentButton, day: element} as dropListData"
             cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="dropListData"
             (cdkDropListDropped)="onDragDrop($event)" (cdkDropListEntered)="onDragEnter($event)"
             class = "mr-1">
          <button mat-fab [color]="element.addButton.color"
                  (mouseenter)="onMouseEnter(dropListData)" (mouseleave)="onMouseLeave(dropListData)" (click)="onAdd(element.date)"
                  (touchstart)="onTouchStart(dropListData)" (touchend)="onTouchEnd(dropListData)">
            <span class="material-icons">add</span>
          </button>
        </div>

      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
            [class.student-view]="currentUser.teacher && i > classroom?.days_show"></mat-row>

  </mat-table>

  <div *ngIf="currentUser?.teacher">
    <div *ngIf="{type: dropListType.StudentList} as dropListData" class="pt-2" mat-accordion
         cdkDropList cdkDropListSortingDisabled cdkDropListOrientation="horizontal" [cdkDropListData]="dropListData"
         (mouseenter)="onMouseEnter(dropListData)" (mouseleave)="onMouseLeave(dropListData)"
         (touchstart)="onTouchStart(dropListData)" (touchend)="onTouchEnd(dropListData)"
         (cdkDropListDropped)="onDragDrop($event)">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Studentenlijst</mat-panel-title>
          <mat-panel-description>Sleep studenten hier naartoe om ze uit te roosteren</mat-panel-description>
        </mat-expansion-panel-header>

        <button *ngFor="let user of classroom.students" mat-fab id="nameButton"
                cdkDrag [cdkDragData]="{color: 'primary', user: user}"  [cdkDragStartDelay]="500"
                (touchstart)="onTouchStart(dropListData)" (touchend)="onTouchEnd(dropListData)"
                color="primary" placement="top" ngbTooltip="{{ user.name }}">

          <span *cdkDragPlaceholder></span>
          <img class="profile-picture" *ngIf="user.profilePicture"
               src="data:image/png;base64,{{user.profilePicture.bytes}}">
          <div *ngIf="!user.profilePicture">{{user.initials}}</div>

        </button>
      </mat-expansion-panel>
    </div>

    <p>Verwissel studenten door ze naar de gewenste plek te slepen.</p>
  </div>
</div>

<div *ngIf="!dataLoaded">
  <h3>Het weekrooster kon helaas niet worden geladen.</h3>
</div>

<!--Formulier om opnieuw in te plannen-->

<form *ngIf="currentUser?.teacher" class="pt-2 pb-2" (ngSubmit)="onSubmit()" [formGroup]="rescheduleForm">

  <div *ngFor="let field of rescheduleMeta">
    <div class="form-group row">
      <label class="col-form-label {{ labelCol }}" [for]="field.id">{{ field.labelText }}</label>

      <input *ngIf="field.element == 'input'" [type]="field.inputType"
             class="form-control {{ dateCol }}" [id]="field.id" [formControlName]="field.controlName">

    </div>

    <div *ngIf="submitted && rescheduleForm.controls[field.controlName].errors" class="alert alert-danger">
      <div *ngIf="rescheduleForm.controls[field.controlName].errors.required">
        Om de planning te veranderen is een datum vereist
      </div>

      <div *ngIf="rescheduleForm.controls[field.controlName].errors.date">
        Deze Datum ligt in het verleden, we kunnen het verleden niet voor u veranderen, hooguit de toekomst
      </div>
    </div>
  </div>

  <div class="text-center">
    <button type="submit" class="btn btn-outline-primary {{  submitCol  }}">Plannen</button>
  </div>

</form>

<div *ngIf="submitted && message" class="alert alert-danger">
  {{ message }}
</div>
