import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule} from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './auth/auth.interceptor';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BaseComponent } from './base/base.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ClassRoomOverviewComponent } from './class-room-overview/class-room-overview.component';
import { WeekviewComponent } from './weekview/weekview.component';
import { NavComponent } from './nav/nav.component';
import { AddStudentViewComponent } from './add-student-view/add-student-view.component';
import { EditWeekviewComponent } from './edit-weekview/edit-weekview.component';
import { EditClassViewComponent } from './edit-class-view/edit-class-view.component';

import { LOCALE_ID } from '@angular/core'
import { registerLocaleData } from '@angular/common';
import localeNL from '@angular/common/locales/nl';
import { AppConfigService } from './app-config.service';
import { EditPasswordViewComponent } from './edit-password-view/edit-password-view.component';
import { HttpErrorInterceptor } from './auth/http-error.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './footer/footer.component';
import { UserOverviewComponent } from './user-overview/user-overview.component';
import { HelpWindowComponent } from './help-window/help-window.component';
import { EditProfilePictureComponent } from './edit-profile-picture/edit-profile-picture.component';
import { MatSortModule } from '@angular/material/sort';
import { ScheduleOverwriterComponent } from './schedule-overwriter/schedule-overwriter.component';

registerLocaleData(localeNL);


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    BaseComponent,
    ClassRoomOverviewComponent,
    PageNotFoundComponent,
    WeekviewComponent,
    NavComponent,
    AddStudentViewComponent,
    EditClassViewComponent,
    EditPasswordViewComponent,
    EditWeekviewComponent,
    FooterComponent,
    UserOverviewComponent,
    EditProfilePictureComponent,
    HelpWindowComponent,
    ScheduleOverwriterComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    DragDropModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatSortModule,
    NgbModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    ImageCropperModule
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfig: AppConfigService) => () => appConfig.loadAppConfig(),
      multi: true,
      deps: [AppConfigService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID, useValue: "nl-NL"
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [EditWeekviewComponent],
})
export class AppModule { }
