import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/app-config.service';
import { OverwrittenDay } from './model/overwritten-day';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class ScheduleService {

  private baseUrl: string;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this.baseUrl = `${appConfig.getConfig().apiUrl}/weekview`;
  }

  updatePresence(presenceUpdateRequest: any): Observable<object> {
    return this.http.patch(`${this.baseUrl}/Presence`, presenceUpdateRequest);
  }

}
