<div class="my-1 d-inline-block w-100">
  <h1 class="float-left col-10">Student:</h1>
  <button mat-mini-fab class="float-right col-2" (click)="getHelp(['editstudentview', 'schedule_overwriter', 'user'])">    <span class="material-icons">help_outline</span>  </button>
</div>

<app-edit-profile-picture [userId]="studentId" [initials]="studentForm.get('initials').value"></app-edit-profile-picture>

<form class="pt-2 pb-2" (ngSubmit)="onSubmit()" [formGroup]="studentForm">

  <div *ngFor="let field of meta">
    <div class="form-group row">
      <label class="col-form-label {{ labelCol }}" [for]="field.controlName">{{ field.labelText }}</label>

      <input *ngIf="field.element == 'input'" [type]="field.inputType" (blur)="onBlur(field.controlName)"
              class="form-control {{ inputCol }}" [id]="field.controlName" [formControlName]="field.controlName">

      <select *ngIf="field.element == 'select'"
              class="form-control {{ inputCol }}" [id]="field.controlName" [formControlName]="field.controlName">

          <option *ngFor="let classroom of classrooms" [value]="classroom.classname">{{ classroom.classname }}</option>
      </select>

      <div *ngIf="field.inputType == 'formGroup'" class="btn-group btn-group-toggle {{ inputCol }}"
           [formGroupName]="field.controlName">

        <ng-container *ngFor="let subField of field.nested">
          <label *ngIf="subField.inputType === 'checkbox'" class="btn"
                [class.btn-primary]="studentForm.get(field.controlName).get(subField.controlName).value"
                [class.btn-danger]="!studentForm.get(field.controlName).get(subField.controlName).value"
                [class.active]="!studentForm.get(field.controlName).get(subField.controlName).value" ngbButtonLabel>
            <input type="checkbox" [formControlName]="subField.controlName" ngbButton>
            {{ subField.labelText }}
          </label>
        </ng-container>

      </div>

    </div>

    <div *ngIf="submitted && studentForm.controls[field.controlName].errors"
          class="alert alert-danger">

      <div *ngIf="studentForm.controls[field.controlName].errors.required">
        Dit veld is vereist
      </div>

      <div *ngIf="!studentForm.controls[field.controlName].errors.required && studentForm.controls[field.controlName].errors.email">
        Ongeldig e-mail adres (voorbeeld: "example@gmail.com")
      </div>

      <div *ngIf="studentForm.controls[field.controlName].errors.pattern && field.controlName === 'name'">
        Een naam moet beginnen met een hoofdletter gevolgd door minstens 1 kleine letter
      </div>

      <div *ngIf="studentForm.controls[field.controlName].errors.pattern && field.controlName === 'initials'">
        Initialen moeten bestaan minstens 1 hoofdletter en mogen niet beginnen of eindigen met een kleine letter
        (voorbeelden: "Kevin de Vries" &rarr; "KV" or "KdV", "John Maynard Keynes" &rarr; "JK" or "JMK")
      </div>

      <div *ngIf="studentForm.controls[field.controlName].errors.min && field.controlName === 'plannerWeight'">
        De planfrequentie mag minimaal gelijk zijn aan {{ studentForm.controls[field.controlName].errors.min.min }}
      </div>

      <div *ngIf="studentForm.controls[field.controlName].errors.max && field.controlName === 'plannerWeight'">
        De planfrequentie mag maximaal gelijk zijn aan {{ studentForm.controls[field.controlName].errors.max.max }}
      </div>

    </div>

    <div *ngIf="submitted && ['password', 'confirmPassword'].includes(field.controlName) &&
                !studentForm.controls[field.controlName].errors && studentForm.errors.passwords"
                class="alert alert-danger">
      Wachtwoorden zijn niet hetzelfde
    </div>

    <div *ngIf="submitted && ['startDate', 'endDate'].includes(field.controlName) &&
      !studentForm.controls[field.controlName].errors && studentForm.errors.date"
      class="alert alert-danger">
      De einddatum kan niet voor de startdatum liggen.
    </div>

  </div>

  <div class="text-center">
    <button type="submit" class="btn btn-outline-primary">Opslaan</button>
  </div>

</form>

<div *ngIf="submitted && message" class="alert alert-danger">
  {{ message }}
</div>

<div *ngIf="student" class="pb-2">
  <h2> Gebruikersplanning aanpassen: </h2>

  <app-schedule-overwriter
    [getScheduleStream]="scheduleOverwriteService.getUserSchedule(student.id)"
    [editScheduleStream]="scheduleOverwriteService.editUserSchedule(student.classroomName, student.id)"
    [removeFromScheduleStream]="scheduleOverwriteService.removeFromUserSchedule(student.classroomName, student.id)"
    [enabledWeekDays]="student.enabledWeekdaysOnSite"
  >
  </app-schedule-overwriter>
</div>