<div class="my-1 d-inline-block w-100">
 <h2 class="float-left col-10">Klas aanpassen:</h2>
 <button mat-mini-fab class="float-right col-2" (click)="getHelp(['editclassview', 'schedule_overwriter', 'classroom'])"><span class="material-icons">help_outline</span></button>
</div>

<form class="pt-2 pb-2" (ngSubmit)="onSubmit()" [formGroup]="classroomForm">

  <div *ngFor="let field of meta">
    <div class="form-group row">
      <label class="col-form-label {{ labelCol }}" [for]="field.controlName">{{ field.labelText }}</label>

      <input *ngIf="field.element == 'input'" [type]="field.inputType"
             class="form-control {{ inputCol }}" [id]="field.controlName" [formControlName]="field.controlName">

      <select *ngIf="field.element == 'select'"
              class="form-control {{ inputCol }}" [id]="field.controlName" [formControlName]="field.controlName">

        <option value="">Geen docent</option>
        <option *ngFor="let teacher of availableTeachers" [value]="teacher.id">
           {{ teacher.initials + ' - ' + teacher.name }}
        </option>
      </select>

      <div *ngIf="field.inputType == 'formGroup'" class="btn-group btn-group-toggle {{ inputCol }}"
           [formGroupName]="field.controlName">

        <ng-container *ngFor="let subField of field.nested">
          <label *ngIf="subField.inputType === 'checkbox'" class="btn"
                [class.btn-primary]="classroomForm.get(field.controlName).get(subField.controlName).value"
                [class.btn-danger]="!classroomForm.get(field.controlName).get(subField.controlName).value"
                [class.active]="!classroomForm.get(field.controlName).get(subField.controlName).value" ngbButtonLabel>
            <input type="checkbox" [formControlName]="subField.controlName" ngbButton>
            {{ subField.labelText }}
          </label>
        </ng-container>

      </div>

      <div class="input-group {{ inputCol }}" *ngIf="field.element == 'email-time'" [formGroupName]="field.controlName">
        <input type="number" class="form-control" (input)="onHoursChange($event, field.controlName)"
               [id]="field.controlName" formControlName="hours">
        <div class="input-group-prepend input-group-append">
          <span class="form-control input-group-text">:</span>
        </div>
        <select class="form-control" formControlName="minutes">
          <option value="00">00</option>
          <option value="30">30</option>
        </select>
      </div>
    </div>

    <div *ngIf="submitted && classroomForm.controls[field.controlName].errors"
         class="alert alert-danger">

      <div *ngIf="classroomForm.controls[field.controlName].errors.required">
        Dit veld is vereist
      </div>

      <div *ngIf="classroomForm.controls[field.controlName].errors.pattern">
        Ongeldig invoerformaat
      </div>

      <div *ngIf="classroomForm.controls[field.controlName].errors.min">
        Dit veld moet minstens gelijk aan {{ classroomForm.controls[field.controlName].errors.min.min }} zijn
      </div>

      <div *ngIf="classroomForm.controls[field.controlName].errors.anyWeekdayEnabled">
        Er moet minstens één dag per week voor een locatie worden ingepland
      </div>

      <div  *ngIf="classroomForm.controls[field.controlName].errors.beforeDate">
        Er moeten meer geplande dagen dan getoonde dagen zijn
      </div>

    </div>

    <div class="alert alert-danger"
         *ngIf="submitted && classroomForm.errors?.beforeDate && !classroomForm.controls[field.controlName].errors &&
                ['days_show', 'days_planned'].includes(field.controlName)">
      Er moeten meer geplande dagen dan getoonde dagen zijn
    </div>
  </div>

  <div *ngIf="classroom">
    <h2> Planning aanpassen: </h2>

    <app-schedule-overwriter
      [getScheduleStream]="scheduleOverwriteService.getClassroomSchedule(classroom.classname)"
      [editScheduleStream]="scheduleOverwriteService.editClassroomSchedule(classroom.classname)"
      [removeFromScheduleStream]="scheduleOverwriteService.removeFromClassroomSchedule(classroom.classname)"
      [enabledWeekDays]="classroom.enabledWeekDays"
    >
    </app-schedule-overwriter>

    <h2>Studenten:</h2>
    <mat-table [dataSource]="classroom?.students" class="mat-elevation-z8">
      <!-- Naam Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Naam </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
      </ng-container>

      <!--Iinitialen Column -->
      <ng-container matColumnDef="initials">
        <mat-header-cell *matHeaderCellDef> Initialen </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.initials}} </mat-cell>
      </ng-container>

      <!-- Einddatum Column -->
    <ng-container matColumnDef="end_date">
      <mat-header-cell *matHeaderCellDef> Einddatum </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="d-none d-md-block">{{ element.endDate | date:'dd-MM-yyyy'}}</div>
        <div title="{{element.name}}" class="d-md-none">{{ element.endDate | date:'dd/MM/yy'}}</div></mat-cell>
    </ng-container>

    <!-- Edit Column -->
    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button *ngIf="!element?.teacher" type="button" class="d-none d-md-block" mat-stroked-button routerLink="{{'/user/edit-student/' + element.id}}">aanpassen</button>
        <button *ngIf="!element?.teacher" type="button" class="d-md-none" mat-stroked-button routerLink="{{'/user/edit-student/' + element.id}}"><mat-icon>edit</mat-icon></button>
        <button mat-stroked-button disabled *ngIf="element?.teacher">Docent</button>
      </mat-cell>
    </ng-container>

      <!-- Remove Column -->
      <ng-container matColumnDef="remove">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button type="button"
                  mat-icon-button (click)="removeStudentFromClassroom(element.id)">
            <span class="material-icons">clear</span>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <button mat-stroked-button type="button" color="primary" routerLink="/user/add-student/{{classname}}">Student toevoegen</button>
  </div>

  <div class="text-center">
    <button *ngIf="classroom" type="submit" class="btn btn-outline-primary">Klas Aanpassen</button>
    <button *ngIf="!classroom" type="submit" class="btn btn-outline-primary">Klas Toevoegen</button>
  </div>

</form>

<div *ngIf="submitted && message" class="alert alert-danger">
  {{ message }}
</div>
