import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { User } from '../user';
import { CurrentUserChangeRequest } from '../model/requests/current-user-change-request';
import { tap, mergeMap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HelpWindowComponent } from '../help-window/help-window.component';
import { Availability } from '../model/availability.enum';
import { Weekdays } from '../model/weekdays';
import { ScheduleOverwriteService } from '../services/schedule-overwrite.service';

@Component({
  selector: 'app-edit-password-view',
  templateUrl: './edit-password-view.component.html',
  styleUrls: ['./edit-password-view.component.css']
})
export class EditPasswordViewComponent implements OnInit {

  availability = Availability;

  currentUser: User;

  meta: any;

  labelCol: string;
  inputCol: string;

  userDataForm: FormGroup;

  submitted = false;

  message: string;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private auth: AuthService,
    private router: Router,
    public  scheduleOverwriteService: ScheduleOverwriteService,
    private dialog: MatDialog,
  ) { }

  protected initTemplateData(): void {
    const weekdayControls = [
      {controlName: 'monday',    element: 'button', labelText: 'Ma'},
      {controlName: 'tuesday',   element: 'button', labelText: 'Di'},
      {controlName: 'wednesday', element: 'button', labelText: 'Wo'},
      {controlName: 'thursday',  element: 'button', labelText: 'Do'},
      {controlName: 'friday',    element: 'button', labelText: 'Vr'},
    ];

    this.meta = [
      {controlName: 'username',        element: 'input', inputType: 'text',     labelText: 'E-mail:'},
      {controlName: 'name',            element: 'input', inputType: 'text',     labelText: 'Naam:'},
      {controlName: 'initials',        element: 'input', inputType: 'text',     labelText: 'Initialen:'},
      {
        controlName: 'weekdayAvailability',
        element: 'div',
        inputType: 'formGroup',
        labelText: 'Dagen beschikbaar: ',
        nested: weekdayControls
      },
      {controlName: 'oldPassword',     element: 'input', inputType: 'password', labelText: 'Oud Wachtwoord:'},
      {controlName: 'newPassword',     element: 'input', inputType: 'password', labelText: 'Nieuw Wachtwoord:'},
      {controlName: 'confirmPassword', element: 'input', inputType: 'password', labelText: 'Herhaal Wachtwoord:'},
    ];

    this.labelCol = 'col-md-4';
    this.inputCol = 'col-md-7';
  }

  protected initForm(): void {
    const oldAndNewPasswordValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
      const oldPass = Boolean(formGroup.get('oldPassword').value);
      const newPass = Boolean(formGroup.get('newPassword').value);
      const confirmPass = Boolean(formGroup.get('confirmPassword').value);
      const allPass = oldPass && newPass && confirmPass;
      const nonePass = !oldPass && !newPass && !confirmPass;
      return (allPass || nonePass) ? null : {passwordsRequired: true};
    };

    const confirmPasswordValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
      const pass = formGroup.get('newPassword').value;
      const confirm = formGroup.get('confirmPassword').value;
      return pass === confirm ? null : {passwordConfirmation: true};
    };

    this.userDataForm = this.formBuilder.group({
      username:            ['', [Validators.required, Validators.email]],
      name:                ['', [Validators.required, Validators.pattern('[A-Z][a-z]+.*')]],
      initials:            ['', [Validators.required, Validators.pattern('[A-Z]([a-z]*[A-Z])*')]],
      oldPassword:         [''],
      newPassword:         [''],
      confirmPassword:     [''],
      weekdayAvailability: this.formBuilder.group({
        monday:    [Availability.Physical],
        tuesday:   [Availability.Physical],
        wednesday: [Availability.Physical],
        thursday:  [Availability.Physical],
        friday:    [Availability.Physical],
        saturday:  [Availability.Unavailable],
        sunday:    [Availability.Unavailable]
      })
    }, {validators: [oldAndNewPasswordValidator, confirmPasswordValidator]});
  }

  private insertUserDataInForm(user: User) {
    this.userDataForm.controls.username.setValue(user.username);
    this.userDataForm.controls.name.setValue(user.name);
    this.userDataForm.controls.initials.setValue(user.initials);

    const weekdayAvailability = this.booleansToAvailability(user.enabledWeekdaysOnSite, user.enabledWeekdaysOnCall);
    this.userDataForm.controls.weekdayAvailability.setValue(weekdayAvailability);
  }

  generateInitialsFromName(name: string) {
    const isUpperCase = (str: string) => /^[A-Z]$/.test(str);
    return name.split('').filter(isUpperCase).join('');
  }

  onBlur(controlName: string) {
    if (controlName === 'name' && !this.userDataForm.get('name').errors && !this.userDataForm.get('initials').value) {
      const initials = this.generateInitialsFromName(this.userDataForm.get('name').value);
      this.userDataForm.controls.initials.setValue(initials);
    }
  }

  ngOnInit(): void {
    this.initTemplateData();
    this.initForm();

    this.auth.getCurrentUser().subscribe({
      next: user => {
        this.currentUser = user;
        this.insertUserDataInForm(user);
      },
      error: err => {
        console.log(err);
      }
    });
  }

  protected buildPersonalDataChangeRequest(): CurrentUserChangeRequest {
    let formValues = {...this.userDataForm.value};

    // Change Availability formGroup value to Weekdays with boolean values
    const [enabledWeekdaysOnSite, enabledWeekdaysOnCall] = this.availabilityToBooleans(formValues.weekdayAvailability);
    formValues.enabledWeekdaysOnSite = enabledWeekdaysOnSite;
    formValues.enabledWeekdaysOnCall = enabledWeekdaysOnCall;
    delete formValues.weekdayAvailability;

    // Delete password data irrelevant to the exact API call
    if (formValues.oldPassword === '' || formValues.newPassword === '') {
      delete formValues.oldPassword;
      delete formValues.newPassword;
    }
    delete formValues.confirmPassword;

    return {...formValues};
  }

  protected editPersonalData() {
    let personalDataRequest = this.buildPersonalDataChangeRequest();
    this.userService.updatePersonalData(personalDataRequest)
    .pipe(
      mergeMap(() => this.auth.setCurrentUser())
    )
    .subscribe(
      () => {
        this.router.navigateByUrl('/');
      },
      (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.message = 'Het gegeven oude wachtwoord is niet correct';
        }
        else {
          this.message = 'Er is een technische fout opgetreden, probeer het later nog eens';
          console.log(`HTTP REQUEST ERROR ${err.status} ${err.statusText}`);
        }
      }
    );
  }

  booleansToAvailability(enabledWeekdaysOnSite: Weekdays, enabledWeekdaysOnCall: Weekdays): Weekdays {
    const keys = Object.keys(enabledWeekdaysOnCall);
    const availability: unknown = {};
    keys.forEach(
      key => {
        availability[key] = enabledWeekdaysOnSite[key] ? Availability.Physical
                          : enabledWeekdaysOnCall[key] ? Availability.Digital
                          : Availability.Unavailable;
      }
    );

    return availability as Weekdays;
  }

  availabilityToBooleans(weekdayAvailability: Weekdays): [Weekdays, Weekdays] {
    const keys = Object.keys(weekdayAvailability);
    const enabledWeekdaysOnSite: unknown = {};
    const enabledWeekdaysOnCall: unknown = {};
    keys.forEach(
      key => {
        switch (weekdayAvailability[key]) {
          case Availability.Physical:
            enabledWeekdaysOnSite[key] = enabledWeekdaysOnCall[key] = true;
            break;
          case Availability.Digital:
            enabledWeekdaysOnSite[key] = false;
            enabledWeekdaysOnCall[key] = true;
            break;
          case Availability.Unavailable:
            enabledWeekdaysOnSite[key] = enabledWeekdaysOnCall[key] = false;
            break;
          default:
            enabledWeekdaysOnSite[key] = enabledWeekdaysOnCall[key] = true;
            break;
        }
      }
    );

    return [enabledWeekdaysOnSite, enabledWeekdaysOnCall] as [Weekdays, Weekdays]
  }

  getWeekdayAvailabilityDescription(availability: Availability) {
    switch (availability) {
      case Availability.Physical:
        return 'Beschikbaar';
      case Availability.Digital:
        return 'Online beschikbaar';
      case Availability.Unavailable:
        return 'Niet beschikbaar';
      default:
        return 'Onbekende beschikbaarheid';
    }
  }

  onWeekdayAvailabilityClick(formGroupName: string, weekdayControlName: string) {
    if (!this.currentUser.teacher) {
      return;
    }

    const formControl = this.userDataForm.get(`${formGroupName}.${weekdayControlName}`);
    switch (formControl.value) {
      case Availability.Physical:
        formControl.setValue(Availability.Digital);
        break;
      case Availability.Digital:
        formControl.setValue(Availability.Unavailable);
        break;
      case Availability.Unavailable:
        formControl.setValue(Availability.Physical);
        break;
      default:
        formControl.setValue(Availability.Physical);
        break;
    }
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.userDataForm.valid) {
      this.editPersonalData();
    }
  }

  getHelp(components: String[]): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "450px";
    dialogConfig.height = "600px";
    dialogConfig.data = {'components': components};
    this.dialog.open(HelpWindowComponent, dialogConfig);
  }

}
