import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '../user';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { map, concatAll } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;

  submitted = false;

  user: User;

  message: string;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private router: Router) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onSubmit() {
    this.submitted = true;

    let username = this.loginForm.get('username').value;
    let password = this.loginForm.get('password').value;

    if (!this.loginForm.invalid) {
      this.auth.authenticateUser(username,password).pipe(
        map(() => this.auth.getCurrentUser()),
        concatAll()
      )
      .subscribe({
        next: () => {
          if (this.auth.isActive()) {
            if (this.auth.isTeacher())
              this.router.navigateByUrl('/user/classroomoverview');
            else
              this.router.navigateByUrl('/user/weekview');
          }
          else {
            this.message = 'Deze gebruiker is niet meer actief';
          }
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          if (err.status == 401) {
            this.message = 'Ongeldige e-mail of wachtwoord';
          }
          else {
            this.message = 'Er is een technische fout opgetreden, probeer het later nog eens.'
          }
        }
      });
    }
  }

}
