<div class="container pt-2">
  <h1 class="text-center">
    <img class="w-25" src="assets/images/educom.jpg">
  </h1>
</div>


<form class="pb-2" (ngSubmit)="onSubmit()" [formGroup]="loginForm">
  <div class="form-group">
    <label for="username">E-mail:</label>
    <input type="text" class="form-control" id="username" formControlName="username">
  </div>
  <div *ngIf="submitted && loginForm.controls.username.errors">
    <div class="alert alert-danger" *ngIf="loginForm.controls.username.errors.required">
      Dit veld is vereist
    </div>
  </div>
  <div class="form-group">
    <label for="password">Wachtwoord:</label>
    <input type="password" class="form-control" id="password" formControlName="password">
  </div>
  <div *ngIf="submitted && loginForm.controls.password.errors">
    <div class="alert alert-danger" *ngIf="loginForm.controls.password.errors.required">
      Dit veld is vereist
    </div>
  </div>
  <div class="text-center">
  <button type="submit" class="btn btn-outline-danger">Login</button>
  </div>
</form>

<div class="alert alert-danger" *ngIf="submitted && message">
  {{ message }}
</div>
