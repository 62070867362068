import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../user.service';
import { User } from '../user';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DataSource, SelectionModel } from '@angular/cdk/collections';
import { HelpWindowComponent } from '../help-window/help-window.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { pipe } from 'rxjs';

@Component({
  selector: 'app-user-overview',
  templateUrl: './user-overview.component.html',
  styleUrls: ['./user-overview.component.css']
})
export class UserOverviewComponent implements OnInit {
  selection: any;

  @ViewChild(MatSort) sort: MatSort;
  users: User[];
  dataSource: any;
  classroomList: Set<string>;
  oldStudents: User[];
  displayedColumns = ['name', 'classroomName', 'end_date', 'edit'];

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.userService.findAllUsers().pipe(
    tap((users: any) => {
      this.classroomList = this.getClassroomList(users);
      users = users.map((user)=>({active: this.isActive(user),...user}));
      this.dataSource = new MatTableDataSource<User>(users);
    })
  ).subscribe(
    (complete)=>{
      this.dataSource.sort = this.sort;}
  );
  }

  getClassroomList(users: User[]){
    let classrooms = users.map((user)=> user.classroomName);
    let filteredClassrooms = classrooms.filter((a)=> a!=null);
    return new Set(filteredClassrooms);
  }




  applyFilter(event) {
    const filterValue = event.value;
    if(filterValue === "old_students"){
      this.dataSource.filterPredicate =
      (data, filter: string) => !filter || data.active === false;
    } else if(filterValue === "new_students"){
      this.dataSource.filterPredicate =
      (data, filter: string) => !filter || data.active === true;
    } else {
      this.dataSource.filterPredicate =
      (data, filter: string) => !filter || data.classroomName != undefined  &&  data.classroomName.includes(filter);
    }
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.getFilter
  }

  getHelp(components: String[]): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "450px";
    dialogConfig.height = "600px";
    dialogConfig.data = {'components': components};
    this.dialog.open(HelpWindowComponent, dialogConfig);
  }

  isActive(user: User): boolean{
    if(user.classroomName == undefined){
      return false;
    }else if(user.endDate && new Date(user.endDate) < new Date(Date.now())){
      return false;
    }else{
      return true;
    }
  }

}
