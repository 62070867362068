<nav class="navbar bg-dark navbar-dark">

  <a class="navbar-brand shadow-none" routerLink="/">
    <img class="w-25" src="assets/images/educom_klein.png">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="collapsibleNavbar">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" routerLink="/user/edit-password/">Persoonlijke gegevens wijzigen</a>
        <a *ngIf="currentUser?.teacher" class="nav-link" routerLink="/user/user-overview">Gebruikers overzicht</a>
        <a class="nav-link" (click)="logout()" routerLink="/">Log uit</a>
      </li>
    </ul>
  </div>

</nav>
