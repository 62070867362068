<div class="my-1 d-inline-block w-100">
  <h2 class="float-left col-10">Gegevens aanpassen:</h2>
  <button mat-mini-fab *ngIf="currentUser?.teacher" class="float-right col-2" (click)="getHelp(['editpasswordview', 'schedule_overwriter', 'user'])"><span class="material-icons">help_outline</span></button>
  <button mat-mini-fab *ngIf="!currentUser?.teacher" class="float-right col-2" (click)="getHelp(['editpasswordview'])"><span class="material-icons">help_outline</span></button>
 </div>

<app-edit-profile-picture [userId]="currentUser.id" [initials]="userDataForm.get('initials').value"></app-edit-profile-picture>

<form class="pt-2 pb-2" (ngSubmit)="onSubmit()" [formGroup]="userDataForm">

  <div *ngFor="let field of meta">
    <div class="form-group row">
      <label class="col-form-label {{ labelCol }}" [for]="field.controlName">{{ field.labelText }}</label>

      <input *ngIf="field.element === 'input'" [type]="field.inputType" (blur)="onBlur(field.controlName)"
             class="form-control {{ inputCol }}" [id]="field.controlName" [formControlName]="field.controlName">

      <div *ngIf="field.inputType === 'formGroup'" class="btn-group btn-group-toggle {{ inputCol }}"
           [formGroupName]="field.controlName">

        <ng-container *ngFor="let subField of field.nested">
          <button type="button" *ngIf="subField.element === 'button'" class="btn"
            [title]="getWeekdayAvailabilityDescription(userDataForm.get(field.controlName).get(subField.controlName).value)"
            [class.btn-primary]="userDataForm.get(field.controlName).get(subField.controlName).value === availability.Physical"
            [class.btn-warning]="userDataForm.get(field.controlName).get(subField.controlName).value === availability.Digital"
            [class.btn-danger]="userDataForm.get(field.controlName).get(subField.controlName).value === availability.Unavailable"
            [class.active]="userDataForm.get(field.controlName).get(subField.controlName).value !== availability.Physical"
            (click)="onWeekdayAvailabilityClick(field.controlName, subField.controlName)"
            ngbButtonLabel
          >
            {{subField.labelText}}
          </button>
        </ng-container>

      </div>
    </div>

    <div *ngIf="submitted && userDataForm.controls[field.controlName].errors"
         class="alert alert-danger">

      <div *ngIf="userDataForm.controls[field.controlName].errors.required">
        Dit veld is vereist
      </div>

      <div *ngIf="!userDataForm.controls[field.controlName].errors.required && userDataForm.controls[field.controlName].errors.email">
        Ongeldig e-mail adres (voorbeeld: "example@gmail.com")
      </div>

      <div *ngIf="userDataForm.controls[field.controlName].errors.pattern && field.controlName === 'name'">
        Een naam moet beginnen met een hoofdletter gevolgd door minstens 1 kleine letter
      </div>

      <div *ngIf="userDataForm.controls[field.controlName].errors.pattern && field.controlName === 'initials'">
        Initialen moeten bestaan minstens 1 hoofdletter en mogen niet beginnen of eindigen met een kleine letter
        (voorbeelden: "Kevin de Vries" &rarr; "KV" or "KdV", "John Maynard Keynes" &rarr; "JK" or "JMK")
      </div>
    </div>

    <div *ngIf="submitted && field.controlName === 'oldPassword' && !userDataForm.get(field.controlName).value &&
                userDataForm.errors?.passwordsRequired"
         class="alert alert-danger">
      Om je wachtwoord te veranderen, moet het oude wachtwoord worden ingevuld
    </div>

    <div *ngIf="submitted && field.controlName === 'newPassword' && !userDataForm.get(field.controlName).value &&
                userDataForm.errors?.passwordsRequired && !userDataForm.errors?.passwordConfirmation"
         class="alert alert-danger">
      Het nieuwe wachtwoord mag niet leeg zijn
    </div>

    <div *ngIf="submitted && ['newPassword', 'confirmPassword'].includes(field.controlName) &&
                userDataForm.errors?.passwordConfirmation"
         class="alert alert-danger">
      Wachtwoorden zijn niet hetzelfde
    </div>

  </div>

  <div class="text-center">
    <button type="submit" class="btn btn-outline-primary">Opslaan</button>
  </div>

</form>

<div *ngIf="submitted && message" class="alert alert-danger">
  {{ message }}
</div>

<div *ngIf="currentUser?.teacher" class="pb-2">
  <h2> Gebruikersplanning aanpassen: </h2>

  <app-schedule-overwriter
    [getScheduleStream]="scheduleOverwriteService.getUserSchedule(currentUser.id)"
    [editScheduleStream]="scheduleOverwriteService.editUserSchedule(currentUser.classroomName, currentUser.id)"
    [removeFromScheduleStream]="scheduleOverwriteService.removeFromUserSchedule(currentUser.classroomName, currentUser.id)"
    [enabledWeekDays]="booleansToAvailability(currentUser.enabledWeekdaysOnSite, currentUser.enabledWeekdaysOnCall)"
    [onlinePresence]="true"
  >
  </app-schedule-overwriter>
</div>
