import { Injectable } from '@angular/core';
import { pipe, Observable, from } from 'rxjs';
import { map, mergeMap, tap, toArray } from 'rxjs/operators';
import { ClassService } from '../class.service';
import { Availability } from '../model/availability.enum';
import { OverwrittenDay } from '../model/overwritten-day';
import { OverwrittenScheduleEntry } from '../model/overwritten-schedule-entry';
import { OverwrittenDaysPutRequest } from '../model/requests/overwritten-days-put-request';
import { UserAvailabilityPutRequest } from '../model/requests/user-availability-put-request';
import { ScheduleService } from '../schedule.service';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleOverwriteService {

  constructor(
    private userService: UserService,
    private classroomService: ClassService,
    private scheduleService: ScheduleService
  ) { }

  // OVERWRITTEN DAY STREAM FUNCTIONS

  overwrittenDayToOverwrittenScheduleEntryOperator() {
    return pipe(
      map<OverwrittenDay,OverwrittenScheduleEntry>(overwrittenDay => {
        return {
          id: overwrittenDay.id,
          date: overwrittenDay.date,
          availability: overwrittenDay.allowPlanning ? Availability.Physical : Availability.Unavailable
        }
      })
    );
  }

  getClassroomSchedule(classname: string) {
    return (start: string, end: string) => {
      return this.classroomService.getOverwrittenDays(classname, start, end)
      .pipe(
        mergeMap(overwrittenDays => from(overwrittenDays)),
        this.overwrittenDayToOverwrittenScheduleEntryOperator(),
        toArray()
      );
    }
  }

  editClassroomSchedule(classname: string) {
    return (start: string, end: string, availability: Availability) => {
      const overwrittenDayPutRequest: OverwrittenDaysPutRequest = {
        start:   start,
        end:     end,
        enabled: availability !== Availability.Unavailable
      };

      return this.classroomService.editOverwrittenDays(classname, overwrittenDayPutRequest)
      .pipe(
        map(overwrittenDays => overwrittenDays instanceof Array ? overwrittenDays : [overwrittenDays]),
        mergeMap(overwrittenDays => from(overwrittenDays)),
        this.overwrittenDayToOverwrittenScheduleEntryOperator(),
        toArray()
      );
    }
  }

  removeFromClassroomSchedule(classname: string) {
    return (date: string, until?: string) =>
      this.classroomService.removeOverwrittenDaysByDateRange(classname, date, until);
  }

  // USER AVAILABILITY STREAM FUNCTIONS

  getUserSchedule(userId: number) {
    return (start: string, end: string): Observable<OverwrittenScheduleEntry[]> =>
      this.userService.getUserAvailabilities(userId, start, end);
  }

  editUserSchedule(classname: string, userId: number) {
    return (start: string , end: string, availability: Availability) => {
      const userAvailabilityPutRequest: UserAvailabilityPutRequest = {
        user:         userId,
        date:         start,
        until:        end,
        availability: availability
      };

      return this.classroomService.editUserAvailability(classname, userAvailabilityPutRequest)
      .pipe(
        map(userAvailability => userAvailability instanceof Array ? userAvailability : [userAvailability]),
        mergeMap(userAvailability => from(userAvailability)),
        toArray<OverwrittenScheduleEntry>()
      );
    }
  }

  removeFromUserSchedule(classname: string, userId: number) {
    return (date: string, until?: string) =>
      this.classroomService.removeUserAvailabilityByDateRange(classname, userId, date, until);
  }

}
