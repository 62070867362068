import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { User } from '../user';
import { UserService } from '../user.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/auth.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { iif } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-edit-profile-picture',
  templateUrl: './edit-profile-picture.component.html',
  styleUrls: ['./edit-profile-picture.component.css']
})
export class EditProfilePictureComponent implements OnInit {

  @Input() userId: number;
  @Input() initials: string;

  currentUser: User;

  imageChangedEvent: any;
  croppedImage: string;

  currentProfilePictureImage: string;
  previewImage: string;

  constructor(
    private userService: UserService,
    private auth: AuthService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.auth.getCurrentUser()
    .subscribe(user => {this.currentUser = user});

    iif(
      () => Boolean(this.userId),
      this.userService.findProfilePicture(this.userId)
    )
    .subscribe(
      profilePicture => {
        this.previewImage = profilePicture ? 'data:image/png;base64,' + profilePicture.bytes : null;
        this.currentProfilePictureImage = this.previewImage;
      },
      err => {
        this.toastr.error('Vanwege technische problemen kon de profielfoto niet worden geladen');
      }
    );
  }

  onFileChanged(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.previewImage = this.croppedImage = event.base64;
  }

  loadImageFailed() {
    this.toastr.warning('De geselecteerde file is geen afbeelding');
    this.imageChangedEvent = undefined;
    this.croppedImage = undefined;
    this.previewImage = this.currentProfilePictureImage;
  }

  onSaveProfilePicture(): void {
    if (!this.croppedImage) {
      this.toastr.warning('Geen afbeelding geselecteerd');
      return;
    }

    this.userService.saveProfilePicture({
      bytes: this.croppedImage.replace('data:image/png;base64,', '')
    })
    .subscribe(
      response => {
        this.currentProfilePictureImage = this.croppedImage;
        this.toastr.success('De profielfoto is succesvol opgeslagen');
      },
      (err: HttpErrorResponse) => {
        if (err.status == 400) {
          this.toastr.error('Deze afbeelding is te groot om als profielfoto in te stellen.');
        }
        else {
          this.toastr.error('Vanwege technische problemen kon de profielfoto niet worden geladen');
          console.log(`HTTP REQUEST ERROR ${err.status} ${err.statusText}`);
        }
      }
    );
  }

  onDeleteProfilePicture(): void {
    if (!this.userId) {
      this.toastr.warning('Geen gebruiker om te bewerken');
    }

    iif(
      () => this.currentUser?.id === this.userId,
      this.userService.deleteCurrentUserProfilePicture(),
      this.userService.deleteProfilePicture(this.userId)
    )
    .subscribe(
      () => {
        this.currentProfilePictureImage = undefined;
        this.previewImage = this.croppedImage;
        this.toastr.success('De profielfoto is succesvol verwijderd');
      },
      err => {
        if (err.status === 304) {
          this.toastr.error('Deze gebruiker heeft geen opgeslagen profielfoto');
        }
        else {
          this.toastr.error('Vanwege technische problemen is de profielfoto niet verwijderd');
        }
      }
    );
  }

}
