import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, of, generate, defer, throwError, from, concat } from 'rxjs';
import { mergeMap, tap, ignoreElements, retryWhen, takeWhile, toArray, finalize } from 'rxjs/operators';
import { NgbDate, NgbDatepickerNavigateEvent, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { OverwrittenScheduleEntry } from '../model/overwritten-schedule-entry';
import { Availability } from '../model/availability.enum';
import { Weekdays } from '../model/weekdays';

@Component({
  selector: 'app-schedule-overwriter',
  templateUrl: './schedule-overwriter.component.html',
  styleUrls: ['./schedule-overwriter.component.scss']
})
export class ScheduleOverwriterComponent implements OnInit, OnChanges {
  // Type variable for template
  availability = Availability;

  // Functions for generic schedule interactions
  @Input() getScheduleStream: (start: string, end: string) => Observable<OverwrittenScheduleEntry[]>;
  @Input() editScheduleStream: (start: string, end: string, availability: Availability) => Observable<OverwrittenScheduleEntry[]>;
  @Input() removeFromScheduleStream: (date: string, until?: string) => Observable<Object>;

  @Input() enabledWeekDays: Weekdays = {
    monday: Availability.Physical,
    tuesday: Availability.Physical,
    wednesday: Availability.Physical,
    thursday: Availability.Physical,
    friday: Availability.Physical,
    saturday: Availability.Unavailable,
    sunday: Availability.Unavailable,
  };

  @Input() onlinePresence = false;

  // View properties
  minDate: NgbDate;

  currentMonth: {year: number, month: number} | null;

  startDate: NgbDate;
  endDate: NgbDate | null = null;

  rangeModeEnabled = false;
  overwriteEnabled = true;

  overwrittenScheduleEntries: Map<number, Map<number, Map<number, OverwrittenScheduleEntry>>> = new Map();

  weekDayAvailabilities: Weekdays;

  readonly weekdayMapper = new Map([
    [1, 'monday'],
    [2, 'tuesday'],
    [3, 'wednesday'],
    [4, 'thursday'],
    [5, 'friday'],
    [6, 'saturday'],
    [7, 'sunday']
  ]);

  constructor(
    private ngbCalendar: NgbCalendar,
    private toastr: ToastrService,
  ) {
    this.minDate = this.ngbCalendar.getNext(this.ngbCalendar.getToday());
  }

  ngOnInit(): void {
    const today = this.ngbCalendar.getToday();
    const dateRange = this.getMonthRange(today.year, today.month, 1, 2);
    this.getScheduleStream(dateRange.start, dateRange.end)
    .subscribe(
      (overwrittenScheduleEntries: OverwrittenScheduleEntry[]) => {
        this.insertOverwrittenScheduleEntries(overwrittenScheduleEntries);
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.enabledWeekDays) {
      this.weekDayAvailabilities = this.enabledWeekdaysToAvailabilities(changes.enabledWeekDays.currentValue);
    }
  }

  private enabledWeekdaysToAvailabilities(enabledWeekDays: Weekdays): Weekdays {
    const entries = Object.entries(enabledWeekDays)
    .map<[string, Availability]>(
      ([key, value]: [string, boolean | Availability]) => {
        let newVal = value;
        if (typeof newVal === 'boolean') {
          newVal = newVal ? Availability.Physical : Availability.Unavailable;
        }
        return [key, newVal];
      }
    );

    const weekdayAvailabilities = {};
    entries.forEach(
      ([key,val]) => { weekdayAvailabilities[key] = val }
    );

    return weekdayAvailabilities as Weekdays;
  }

  convertNgbDateToString(date: NgbDate): string {
    return [date.year,date.month,date.day].map((n) => n.toString().padStart(2,'0')).join('-');
  }

  convertStringToNgbDate(dateString: string): NgbDate {
    let [year,month,day] = dateString.split('-').map(val => Number.parseInt(val));
    return new NgbDate(year,month,day);
  }

  getAvailabilityDescription(date: NgbDate) {
    if (this.isOverwritten(date)) {
      switch(this.getOverwrittenScheduleEntry(date).availability) {
        case Availability.Physical:
          return 'Ingeroosterd';
        case Availability.Digital:
          return 'Online ingeroosterd';
        case Availability.Unavailable:
          return 'Uitgeroosterd';
        default:
          return 'Onbekende roostering';
      }
    }

    const weekdayNumber = this.ngbCalendar.getWeekday(date);
    switch(this.weekDayAvailabilities[this.weekdayMapper.get(weekdayNumber)]) {
      case Availability.Physical:
        return 'Beschikbaar';
      case Availability.Digital:
        return 'Online beschikbaar';
      case Availability.Unavailable:
        return 'Niet beschikbaar';
      default:
        return 'Onbekende beschikbaarheid';
    }
  }

  hasWeekdayAvailability(date: NgbDate, availability: Availability) {
    const weekdayNumber = this.ngbCalendar.getWeekday(date);
    return this.weekDayAvailabilities[this.weekdayMapper.get(weekdayNumber)] === availability;
  }

  isWeekdayUnavailable(date: NgbDate) {
    return this.hasWeekdayAvailability(date, Availability.Unavailable);
  }

  isWeekdayPhysical(date: NgbDate) {
    return this.hasWeekdayAvailability(date, Availability.Physical);
  }

  isWeekdayDigital(date: NgbDate) {
    return this.hasWeekdayAvailability(date, Availability.Digital);
  }

  isOverwritten(date: NgbDate) {
    return Boolean(this.getOverwrittenScheduleEntry(date));
  }

  isUnavailable(date: NgbDate) {
    return this.getOverwrittenScheduleEntry(date)?.availability === Availability.Unavailable;
  }

  isPhysical(date: NgbDate) {
    return this.getOverwrittenScheduleEntry(date)?.availability === Availability.Physical;
  }

  isDigital(date: NgbDate) {
    return this.getOverwrittenScheduleEntry(date)?.availability === Availability.Digital;
  }

  inRange(date: NgbDate) {
    return date.after(this.startDate) && date.before(this.endDate);
  }

  isRangeBorder(date: NgbDate) {
    return date.equals(this.startDate) || date.equals(this.endDate);
  }

  isRange(date: NgbDate) {
    return this.inRange(date) || this.isRangeBorder(date);
  }

  getMonthEndDate(year: number, month: number) {
    const date: NgbDate = new NgbDate(year, month, 31);

    while(!this.ngbCalendar.isValid(date)) {
      date.day -= 1;
    }

    return date;
  }

  getMonthRange(year: number, month: number, prev = 0, next = prev) {
    const baseDate = new NgbDate(year, month, 1);
    const startDate = this.ngbCalendar.getPrev(baseDate,'m', prev);
    const endMonth = this.ngbCalendar.getNext(baseDate, 'm', next);
    const endDate = this.getMonthEndDate(endMonth.year, endMonth.month);

    return {
      start: this.convertNgbDateToString(startDate),
      end: this.convertNgbDateToString(endDate)
    }
  }

  insertOverwrittenScheduleEntries(overwrittenScheduleEntries: OverwrittenScheduleEntry[], resetMonths = true) {
    let monthSet: Set<string> = new Set();
    overwrittenScheduleEntries.forEach((overwrittenScheduleEntry) => {
      let date = this.convertStringToNgbDate(overwrittenScheduleEntry.date);
      let monthSetString = [date.year, date.month].toString();

      if (!this.overwrittenScheduleEntries.has(date.year))
        this.overwrittenScheduleEntries.set(date.year, new Map());

      if (!this.overwrittenScheduleEntries.get(date.year).has(date.month) || (resetMonths && !monthSet.has(monthSetString))) {
        this.overwrittenScheduleEntries.get(date.year).set(date.month, new Map());
        monthSet.add(monthSetString);
      }

      this.overwrittenScheduleEntries.get(date.year).get(date.month).set(date.day, overwrittenScheduleEntry);
    });
  }

  resetMonthRange(year: number, month: number, prev = 0, next = prev) {
    const dateMonth = new NgbDate(year, month, 1);
    const startMonth = this.ngbCalendar.getPrev(dateMonth, 'm', prev);
    const endMonth = this.ngbCalendar.getNext(dateMonth, 'm', next);
    for (let date = startMonth; !date.after(endMonth); date = this.ngbCalendar.getNext(date, 'm')) {
      this.overwrittenScheduleEntries?.get(date.year)?.delete(date.month);
    }
  }

  getOverwrittenScheduleEntry(date: NgbDate) {
    return this.overwrittenScheduleEntries.get(date.year)?.get(date.month)?.get(date.day);
  }

  removeOverwrittenScheduleEntries(date: NgbDate) {
    return this.overwrittenScheduleEntries.get(date.year)?.get(date.month)?.delete(date.day);
  }

  toggleSelectionMode() {
    this.rangeModeEnabled = !this.rangeModeEnabled;
    if (this.endDate) {
      this.startDate = this.endDate;
    }
    this.endDate = null;
  }

  generateDateRangeObservable(startDate: NgbDate, endDate: NgbDate): Observable<NgbDate> {
    return generate(startDate, date => !date.after(endDate), date => this.ngbCalendar.getNext(date));
  }

  onDateSelect(date: NgbDate) {
    if (!this.rangeModeEnabled) {
      this.startDate = date;
    }
    else if (!this.startDate && !this.endDate) {
      this.startDate = date;
    }
    else if (this.startDate && !this.endDate && date.after(this.startDate)) {
      this.endDate = date;
    }
    else {
      this.endDate = null;
      this.startDate = date;
    }
  }

  onNavigate(event: NgbDatepickerNavigateEvent) {
    // TODO (OPTIMIZATION) incorporate current date and cached months in month range selection

    this.currentMonth = event.next;
    const nextRange = this.getMonthRange(event.next.year, event.next.month, 1, 2);

    this.getScheduleStream(nextRange.start, nextRange.end)
    .subscribe({
      next: (overwrittenScheduleEntry) => {
        this.insertOverwrittenScheduleEntries(overwrittenScheduleEntry);
      },
      error: () => {
        this.toastr.error('Vanwege technische problemen zijn er geen overgeschreven dagen opnieuw ingeladen');
      }
    });
  }

  onScheduleEdit(availability: Availability) {
    if (!this.startDate || !this.overwriteEnabled) {
      return;
    }

    this.overwriteEnabled = false;

    const startDate = this.convertNgbDateToString(this.startDate);
    const endDate = this.convertNgbDateToString((this.rangeModeEnabled && this.endDate) ? this.endDate : this.startDate);

    this.editScheduleStream(startDate, endDate, availability)
    .pipe(
      tap(overwrittenScheduleEntry => {this.insertOverwrittenScheduleEntries(overwrittenScheduleEntry, false)}),
      finalize(() => {this.overwriteEnabled = true})
    )
    .subscribe({
      error: (err: HttpErrorResponse) => {
        console.log(`HTTP REQUEST ERROR ${err.status} ${err.message}`);
        this.toastr.error('Vanwege technische problemen zijn de veranderingen niet succesvol aangebracht');
      },
      complete: () => {
        this.toastr.success('De veranderingen zijn succesvol aangebracht');
      }
    });
  }

  onScheduleRemoval() {
    if (!this.startDate || !this.overwriteEnabled) {
      return;
    }

    this.overwriteEnabled = false

    const startDate = this.startDate;
    const endDate = (this.rangeModeEnabled && this.endDate) ? this.endDate : this.startDate;
    const [startDateString, endDateString] = [startDate, endDate].map(this.convertNgbDateToString);

    const removeRangeFromSchedule = this.removeFromScheduleStream(startDateString, endDateString)
    .pipe(
      ignoreElements()
    );

    const removeRangeLocally = this.generateDateRangeObservable(startDate, endDate)
    .pipe(
      tap(date => this.removeOverwrittenScheduleEntries(date)),
      ignoreElements()
    );

    concat(removeRangeFromSchedule, removeRangeLocally)
    .pipe(
      finalize(() => {this.overwriteEnabled = true})
    )
    .subscribe({
      error: err => {
        if (err instanceof HttpErrorResponse && err.status === 404) {
          this.toastr.info('Geen overschrijvingen gevonden om te kunnen verwijderen');
        }
        else {
          this.toastr.error('Vanwege technische problemen zijn de veranderingen niet succesvol aangebracht');
        }
      },
      complete: () => {
        this.toastr.success('De veranderingen zijn succesvol aangebracht');
      }
    });
  }

  onScheduleRefresh() {
    if (!this.currentMonth || !this.overwriteEnabled) {
      return;
    }

    this.overwriteEnabled = false
    const dateRange = this.getMonthRange(this.currentMonth.year, this.currentMonth.month, 1, 2);

    this.getScheduleStream(dateRange.start, dateRange.end)
    .pipe(
      tap(overwrittenScheduleEntries => {
        this.resetMonthRange(this.currentMonth.year, this.currentMonth.month, 1, 2);
        this.insertOverwrittenScheduleEntries(overwrittenScheduleEntries);
      }),
      finalize(() => {this.overwriteEnabled = true})
    )
    .subscribe({
      error: (err: HttpErrorResponse) => {
        console.log(`HTTP REQUEST ERROR ${err.status} ${err.message}`);
        this.toastr.error('Vanwege technische problemen zijn er geen overgeschreven dagen opnieuw ingeladen');
      },
      complete: () => {
        this.toastr.success('De overgeschreven dagen zijn succesvol ingeladen');
      }
    });
  }

}
