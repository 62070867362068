import { Injectable } from '@angular/core';
import { User } from './user';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Classroom } from './classroom';
import { LoginResponse } from './login-response';
import { Password } from './password';
import { AppConfigService } from 'src/app/app-config.service';
import { CurrentUserChangeRequest } from './model/requests/current-user-change-request';
import { ProfilePicture } from './model/profile-picture';
import { UserAvailability } from './model/user-availability';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class UserService {

  private baseUrl: string;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this.baseUrl = `${appConfig.getConfig().apiUrl}/users`;
  }

  // GET ALL USERS

  findAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl);
  }

  // AUTHENTICATE USER

  authenticateUser(username: string, password: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${this.baseUrl}/login`,
    {
      username: username,
      password: password
    });
  }

  // NOTIFY PRESENCE

  notifyPresence(id: number, date: Date): Observable<object> {
    return this.http.post(`${this.baseUrl}/${id}/present/${date}`,{});
  }

  // REMOVE PRESENCE NOTIFICATION

  removePresenceNotification(id: number, date: Date): Observable<object> {
    return this.http.delete(`${this.baseUrl}/${id}/present/${date}`);
  }

  // LIST OF CLASSES

  findClassesFromUserId(id: number): Observable<Classroom[]> {
    return this.http.get<Classroom[]>(`${this.baseUrl}/${id}/classes`);
  }

  // ADD USER

  saveUser(user: User): Observable<User> {
    return this.http.post<User>(this.baseUrl, user);
  }

  // GET USER BY ID

  findUserById(id: number): Observable<User> {
    return this.http.get<User>(`${this.baseUrl}/id/${id}`);
  }

  // GET CURRENT USER

  findCurrentUser(): Observable<User> {
    return this.http.get<User>(`${this.baseUrl}/current`);
  }

  // EDIT USER

  updatePersonalData(personalDataChangeRequest: CurrentUserChangeRequest) {
    return this.http.patch(`${this.baseUrl}/current`, personalDataChangeRequest);
  }

  // USER PROFILE PICTURE API CALLS

  findCurrentUserProfilePicture() {
    return this.http.get<ProfilePicture>(`${this.baseUrl}/current/image`);
  }

  findProfilePicture(id: number) {
    return this.http.get<ProfilePicture>(`${this.baseUrl}/${id}/image`);
  }

  saveProfilePicture(image: {bytes: string}) {
    return this.http.put(`${this.baseUrl}/current/image`, image, {observe: 'response'});
  }

  deleteCurrentUserProfilePicture() {
    return this.http.delete(`${this.baseUrl}/current/image`, {observe: 'response'});
  }

  deleteProfilePicture(id: number) {
    return this.http.delete(`${this.baseUrl}/${id}/image`, {observe: 'response'});
  }

  // EDIT PASSWORD

  updateUser(user: User): Observable<User> {
    return this.http.patch<User>(`${this.baseUrl}`, user);
  }

  // REMOVE USER

  removeUser(id: number): Observable<object> {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }

  // USER AVAILABILITY API CALLS

  getUserAvailabilities(userId: number, start: string, end: string = start) {
    const httpParams = new HttpParams().set('start', start).set('end', end);
    return this.http.get<UserAvailability[]>(`${this.baseUrl}/${userId}/availability`, {params: httpParams});
  }

}
