import { Injectable } from '@angular/core';
import { User } from '../user';
import { UserService } from '../user.service';
import { LoginResponse } from '../login-response';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class AuthService {

  private user: User = null;
  private token: string = null;

  constructor(private userService: UserService) { }

  setCurrentUser(): Observable<User> {
    return this.userService.findCurrentUser()
    .pipe(
      tap((user: User) => {this.user = user})
    );
  }

  getCurrentUser(): Observable<User> {
    if (this.token !== null && this.user === null) {
      return this.setCurrentUser();
    }
    else {
      return of(this.user);
    }
  }

  setSessionToken(): void {
    this.token = this.getTokenFromSession();
  }

  getTokenFromSession(): string {
    return sessionStorage.getItem('token');
  }

  getToken(): string {
    return this.token;
  }

  getSubjectFromToken(): string {
    let payloadEnc: string;
    let tokenFields: string[];
    let payload: any;

    if (this.token === null)
      return null;

    tokenFields = this.token.split(".").slice(0,3);

    if (tokenFields.length != 3)
      return null;

    [,payloadEnc,] = tokenFields;
    payload = JSON.parse(atob(payloadEnc));

    return payload.sub;
  }

  isLoggedIn(): boolean {
    return this.token !== null && Boolean(this.user);
  }

  isActive(): boolean {
    return this.isLoggedIn() && (this.user.endDate === null || Date.now() < new Date(this.user.endDate).getTime())
  }

  isTeacher(): boolean {
    return this.isLoggedIn() && this.user.teacher;
  }

  authenticateUser(username: string, password: string) {
    return this.userService.authenticateUser(username,password).pipe(
      map(
        (response: LoginResponse) => {
          this.token = response.token;
          sessionStorage.setItem('token',this.token);
        }
      )
    );
  }

  logout(): void {
    this.user = null;
    this.token = null
    sessionStorage.clear();
  }

  isAuthenticated(): boolean {
    return this.isActive();
  }
}
