<div class="my-1 d-inline-block w-100">
    <h1 class="float-left col-10">Klas overzicht</h1>  <button mat-mini-fab class="float-right col-2" (click)="getHelp(['classroomoverview'])"><span class="material-icons">help_outline</span></button>
  </div>

  <mat-table [dataSource]="classroomlist" class="mat-elevation-z8">
  <!-- Locatie Column -->
  <ng-container matColumnDef="class_name">
    <mat-header-cell *matHeaderCellDef> Locatie </mat-header-cell>
    <mat-cell *matCellDef="let element"> <button mat-stroked-button title="{{ element.classname }}" class="text-truncate" routerLink="{{'/user/weekview/' + element.classname}}"> {{ element.classname }} </button></mat-cell>
  </ng-container>

  <!-- Docent Column -->
  <ng-container matColumnDef="teacher_name">
    <mat-header-cell *matHeaderCellDef> Docent </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class="d-none d-sm-block">{{element.teacher ? element.teacher.name : ''}}</div>
      <div class="d-sm-none" title="{{ element.teacher?.name }}">{{element.teacher ? element.teacher.initials : ''}}</div>

    </mat-cell>
  </ng-container>

  <!-- Aantal plekken Column -->
  <ng-container matColumnDef="size">
    <mat-header-cell *matHeaderCellDef> Aantal plekken </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.size}} </mat-cell>
  </ng-container>

  <!-- Edit Column -->
  <ng-container matColumnDef="edit">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element">
      <button mat-stroked-button class="d-none d-md-block m-0" routerLink="{{'/user/edit-classroom/' + element.classname}}">aanpassen</button>
      <button mat-stroked-button class="d-md-none m-0" routerLink="{{'/user/edit-classroom/' + element.classname}}"><mat-icon>edit</mat-icon></button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

<div *ngIf="!dataLoaded">
  <h3>Door een technische storing kon het klassenoverzicht kon helaas niet worden geladen.</h3>
</div>

<div>
  <button mat-raised-button routerLink="/user/edit-classroom">Klas toevoegen</button>
  <button mat-raised-button routerLink="/user/user-overview">Gebruikers overzicht</button>
</div>
