<div *ngIf="helpSubjects.includes('weekview')">
   <p> Sleep de studenten naar een andere plek in het rooster om het rooster te wijzigen.<br>
    Om nieuwe studenten toe te voegen: sleep ze van de lijst naar een dag of klik op de plus.<br>
    Om studenten te verwijderen: sleep ze naar het verwijder gebied.<br>
    Om twee studenten om te wisselen: sleep ze van de lijst naar degene met wie je ze wil wisselen.<br></p>
</div>

<div *ngIf="helpSubjects.includes('editclassview')">
  <h4>Formulier voor het aanmaken/aanpassen van klassen</h4>
    <table>
        <tr>
            <th>Veld:</th>
            <th>Betreft:</th>
        </tr>
        <tr>
            <th>Locatie</th>
            <td>locatie</td>
        </tr>
        <tr>
            <th>Aantal plekken</th>
            <td>aantal werkplekken maximaal tegelijk beschikbaar op deze locatie</td>
        </tr>
        <tr>
            <th>Getoonde dagen aan student</th>
            <td>aantal dagen dat een student in het rooster vooruit ziet</td>
        </tr>
        <tr>
            <th>Aantal dagen dat vooruit wordt gepland:</th>
            <td>Aantal dagen tussen nu en de volgende in te plannen dag</td>
        </tr>
        <tr>
            <th>Tijd waarop de studenten geëmaild worden</th>
            <td>Tijdstip op vrijdag waarop de studenten het rooster geëmaild krijgen.</td>
        </tr>
        <tr>
            <th>Tijd waarop de docent wordt geëmaild</th>
            <td>Tijdstip op vrijdag waarop de docent een herinnering krijgt om laatste aanpassingen te doen voor de studenten geëmaild worden</td>
        </tr>
        <tr>
            <th>Docent</th>
            <td>De docent van de betreffende klas</td>
        </tr>
        <tr>
            <th>Vaste dagen:</th>
            <td>Voor welke weekdagen er standaard een planning gemaakt wordt</td>
        </tr>
    </table>

    <h4>Studenten overzicht</h4>
    <p>
        Klik op aanpassen om gegevens van een student toe te voegen.<br>
        Klik op het kruisje om een student uit de klas te halen.<br>
        <strong>Let op: </strong>Dit verwijdert de student niet, de student staat alleen niet meer in het overzicht van de klas.<br>
        <strong>Student toevoegen</strong> brengt je naar het formulier om een nieuwe student te maken en aan de klast toe te voegen.
    </p>
 </div>

 <div *ngIf="helpSubjects.includes('editstudentview')">
    <h4>Formulier voor het aanmaken/aanpassen van studenten</h4>
      <table>
          <tr>
              <th>Veld:</th>
              <th>Betreft:</th>
          </tr>
          <tr>
              <th>Emailadres</th>
              <td>E-mailadres</td>
          </tr>
          <tr>
              <th>Naam:</th>
              <td>Naam</td>
          </tr>
          <tr>
              <th>Initialen</th>
              <td>initialen</td>
          </tr>
          <tr>
              <th>Wachtwoord:</th>
              <td>het wachtwoord van de gebruiker. Laat dit leeg als dat niet aangepast hoeft te worden</td>
          </tr>
          <tr>
              <th>herhaal wachwoord:</th>
              <td>nogmaals je wachtwoord</td>
          </tr>
          <tr>
              <th>Klas</th>
              <td>De klas waarvan de student deel uit maakt</td>
          </tr>
          <tr>
              <th>Planfrequentie</th>
              <td>Hoe vaak iemand ingepland wordt: 1 (vaak) tot 5 (weinig)</td>
          </tr>
          <tr>
              <th>Start/Eind datum</th>
              <td>Studenten worden niet ingeroosterd voor de startdatum of na de einddatum.</td>
          </tr>
      </table>
    </div>

    <div *ngIf="helpSubjects.includes('schedule_overwriter')">

      <h5>Beschikbaarheid</h5>
      <h4>Het aanpassen van de planning</h4>
      <p>legenda:</p>
      <table>
          <tr>
              <th>Kleur:</th>
              <th>Duidt aan:</th>
          </tr>
          <tr>
              <th>Wit</th>
              <td>Vaste ingeplande dagen</td>
          </tr>
          <tr>
            <th>Groen</th>
            <td>Handmatig ingeplande dagen</td>
          </tr>
          <tr>
            <th>Geel</th>
            <td>Vaste uitgesloten dagen</td>
          </tr>
          <tr>
              <th>Oranje</th>
              <td>Handmatig uitgesloten dagen</td>
          </tr>
          <tr>
            <th>Blauw</th>
            <td>Geselecteerde dagen</td>
        </tr>
      </table>

   <p>
      Uitgesloten dag: dag waarvoor geen planning wordt gemaakt
    </p>
    <table>
        <tr>
            <th>knop</th>
            <th>functie</th>
        </tr>
        <tr>
            <th>Inroosteren</th><!--TO DO: omzetten naar deze dag wordt je automatisch ingeroosterd.-->
            <td *ngIf="helpSubjects.includes('user')">Voor deze dag kan je worden ingeroosterd</td>
            <td *ngIf="helpSubjects.includes('classroom')">Voor deze dag wordt een planning gemaakt</td>
          </tr>
          <tr *ngIf="helpSubjects.includes('user')">
              <th>Online aanwezig</th>
              <td>Voor deze dag wordt je niet ingeroosterd maar ben je wel aan het werk</td>
          </tr>
          <tr>
              <th>Uitroosteren</th>
              <td *ngIf="helpSubjects.includes('user')">Voor deze dag kan je niet worden ingeroosterd</td>
              <td *ngIf="helpSubjects.includes('classroom')">Voor deze dag wordt geen planning gemaakt</td>
          </tr>
          <tr>
            <th>Verwijderen</th>
            <td>Geselecteerde dagen resetten naar default</td>
        </tr>
        <tr>
            <th>Verversen</th>
            <td>Vraag van de server een nieuwe versie aan, wanneer meerdere mensen tegelijk dingen aanpassen</td>
        </tr>
        <tr>
          <th>Enkelmodus/Rangemodus</th>
          <td>Enkelmodus selecteerd enkele dagen,<br> rangemodus selecteerd een reeks van dagen,<br> Klik op de knop om van modus te wisselen</td>
      </tr>
    </table>
 </div>

 <div *ngIf="helpSubjects.includes('classroomoverview')">
    <p> Homescherm voor docenten<br>
     Hier ziet u een overzicht van alle klassen,<br>
     Klik op de naam van de klas om het weekrooster te zien,<br>
     Of klik op aanpassen om de gegevens van de klas aan te passen of in te kijken.<br>
     Ook kunt u nieuwe klassen aanmaken, of naar het gebruikersoverzicht verdergaan.
    </p>

 </div>


 <div *ngIf="helpSubjects.includes('useroverview')">
    <h3> Gebruikersoverzicht</h3>
    <p>
     Klik op de kolommen om te sorteren,<br>
     Gebruk de knoppen om specifiek oud-studenten of huidige studenten te selecteren,<br>
     Selecteer de optie oud-studenten voor oud-studenten,<br>
     </p>
 </div>
