import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BaseComponent } from './base/base.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ClassRoomOverviewComponent } from './class-room-overview/class-room-overview.component';
import { WeekviewComponent } from './weekview/weekview.component';
import { TeacherGuard } from './auth/teacher.guard';
import { LoginGuard } from './auth/login.guard';
import { UserGuard } from './auth/user.guard';
import { LoginComponent } from './login/login.component';
import { AddStudentViewComponent } from './add-student-view/add-student-view.component';
import { EditClassViewComponent } from './edit-class-view/edit-class-view.component';
import { EditPasswordViewComponent } from './edit-password-view/edit-password-view.component';
import { EditWeekviewComponent } from './edit-weekview/edit-weekview.component';
import { UserOverviewComponent } from './user-overview/user-overview.component';

const routes: Routes = [
  {path: 'user', component: BaseComponent,  canActivate: [UserGuard], children: [
    {path: 'classroomoverview', component: ClassRoomOverviewComponent, canActivate: [TeacherGuard]},
    {path: 'weekview', component: WeekviewComponent},
    {path: 'weekview/:classname', component: WeekviewComponent},
    {path: 'edit-password', component: EditPasswordViewComponent},
    {path: 'edit-student/:id'  , component: AddStudentViewComponent, canActivate: [TeacherGuard]},
    {path: 'add-student/:classroomName', component: AddStudentViewComponent, canActivate: [TeacherGuard]},
    {path: 'edit-classroom/:classname', component: EditClassViewComponent, canActivate: [TeacherGuard]},
    {path: 'edit-classroom', component: EditClassViewComponent, canActivate: [TeacherGuard]},
    {path: 'edit-schedule',  component: EditWeekviewComponent, canActivate: [TeacherGuard]},
    {path: 'user-overview', component: UserOverviewComponent, canActivate: [TeacherGuard]},
    {path: '**', component: PageNotFoundComponent},
  ]},
  {path: '', component: LoginComponent, canActivate: [LoginGuard]},
  {path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
