import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Classroom } from '../classroom';
import { ClassService } from '../class.service';
import { User } from '../user';
import { UserService } from '../user.service';
import { UserResourceService } from '../user-resource.service';
import { map, tap, filter, ignoreElements, mergeMap, toArray } from 'rxjs/operators';
import { MatTable } from '@angular/material/table';
import { Observable, merge, of, from, iif, pipe } from 'rxjs';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { HelpWindowComponent } from '../help-window/help-window.component';
import { OverwrittenScheduleEntry } from '../model/overwritten-schedule-entry';
import { OverwrittenDay } from '../model/overwritten-day';
import { ScheduleService } from '../schedule.service';
import { ScheduleOverwriteService } from '../services/schedule-overwrite.service';

@Component({
  selector: 'app-edit-class-view',
  templateUrl: './edit-class-view.component.html',
  styleUrls: ['./edit-class-view.component.css']
})
export class EditClassViewComponent implements OnInit {

  @ViewChild(MatTable) table: MatTable<any>;
  displayedColumns = ['name', 'initials', 'end_date', 'edit', 'remove'];

  meta: any;

  labelCol: string;
  inputCol: string;

  classroomForm: FormGroup;
  checkboxGroupForm: FormGroup;

  classname: string;
  classroom: Classroom;
  classrooms: Classroom[];
  availableTeachers: User[];

  submitted = false;
  message: string;

  constructor(
    private formBuilder: FormBuilder,
    private classroomService: ClassService,
    private dialog: MatDialog,
    private userService: UserService,
    private resourceService: UserResourceService,
    private router: Router,
    private route: ActivatedRoute,
    public  scheduleOverwriteService: ScheduleOverwriteService
  ) {}

  protected initTemplateData(): void {
    const weekdayControls = [
      {controlName: 'monday',    element: 'input', inputType: 'checkbox', labelText: 'Ma'},
      {controlName: 'tuesday',   element: 'input', inputType: 'checkbox', labelText: 'Di'},
      {controlName: 'wednesday', element: 'input', inputType: 'checkbox', labelText: 'Wo'},
      {controlName: 'thursday',  element: 'input', inputType: 'checkbox', labelText: 'Do'},
      {controlName: 'friday',    element: 'input', inputType: 'checkbox', labelText: 'Vr'},
    ];


    this.meta = [
      {controlName: 'classname',       element: 'input',  inputType: 'text',      labelText: 'Locatie:'},
      {controlName: 'size',            element: 'input',  inputType: 'number',    labelText: 'Aantal plekken:'},
      {controlName: 'days_show',       element: 'input',  inputType: 'number',    labelText: 'Getoonde dagen aan studenten'},
      {controlName: 'days_planned',    element: 'input',  inputType: 'number',    labelText: 'Aantal dagen dat vooruit wordt gepland:'},
      {controlName: 'mailStudentTime', element: 'email-time', labelText: 'Tijd waarop de studenten geëmaild worden:'},
      {controlName: 'mailTeacherTime', element: 'email-time', labelText: 'Tijd waarop de Docent geëmaild wordt:'},
      {controlName: 'teacherId',       element: 'select', inputType: 'text',      labelText: 'Docent:'},
      {controlName: 'enabledWeekDays', element: 'div',    inputType: 'formGroup', labelText: 'Vaste dagen: ', nested: weekdayControls}
    ];

    this.labelCol = 'col-md-4';
    this.inputCol = 'col-md-7';
  }

  protected initForm(): void {
    const anyWeekdayEnabledValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
      const enabled: boolean[] = Object.values(formGroup.value);
      return enabled.reduce((a,b) => a || b) ? null : {anyWeekdayEnabled: true};
    };

    const daysValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
      const days_planned = Number(formGroup.get('days_planned').value);
      const days_show = Number(formGroup.get('days_show').value);
      return days_planned >= days_show ? null : {beforeDate: true};
    }

    this.classroomForm = this.formBuilder.group({
      classname:       ['', [Validators.required, Validators.pattern('[A-Z][a-z]+')]],
      size:            ['', [Validators.required, Validators.min(1)]],
      days_show:       ['', [Validators.required, Validators.min(0)]],
      days_planned:    ['', [Validators.required, Validators.min(0)]],
      mailStudentTime: this.formBuilder.group({
        hours: ['18'],
        minutes: ['00'],
        seconds: ['00']
      }),
      mailTeacherTime: this.formBuilder.group({
        hours: ['10'],
        minutes: ['00'],
        seconds: ['00']
      }),
      teacherId:       [''],
      enabledWeekDays: this.formBuilder.group({
          monday:    [true],
          tuesday:   [true],
          wednesday: [true],
          thursday:  [true],
          friday:    [true],
          saturday:  [false],
          sunday:    [false]
      },{validators:[anyWeekdayEnabledValidator]})
    },
    {validators: [daysValidator]});
  }

  private getAllAvailableTeachers(): Observable<never> {
    const isAvailableTeacher = (user: User) => {
      return user.teacher && (user.classroomName === null || user.classroomName === this.classname);
    }
    return this.resourceService.getAllUsers().pipe(
      tap((userList: User[]) => {this.availableTeachers = userList.filter(isAvailableTeacher)}),
      ignoreElements()
    );
  }

  private processClassroom(classname: string): Observable<never> {
    return of(classname).pipe(
      filter(classname => Boolean(classname)),
      mergeMap(classname => this.classroomService.findClassroomByName(classname)),
      tap((classroom: Classroom) => {
        this.classroom = classroom;

        const compareSimpleValues = (a,b) => a < b ? -1 : a > b ? 1 : 0;
        this.classroom.students.sort((a,b) => compareSimpleValues(new Date(a.startDate), new Date(b.startDate)));
        this.insertClassroomInForm();
      }),
      ignoreElements()
    );
  }

  private insertClassroomInForm(): void {
    this.classroomForm.controls.classname.setValue(this.classroom.classname);
    this.classroomForm.controls.size.setValue(this.classroom.size);
    this.classroomForm.controls.days_show.setValue(this.classroom.days_show);
    this.classroomForm.controls.days_planned.setValue(this.classroom.days_planned);
    this.classroomForm.controls.teacherId.setValue(this.classroom.teacher ? this.classroom.teacher.id : '');

    const splitTime = (time: string) => {
      const [hours, minutes, seconds] = time.split(':')
      return {hours: hours, minutes: minutes, seconds: seconds}
    }

    this.classroomForm.controls.mailStudentTime.setValue(splitTime(this.classroom.mailStudentTime));
    this.classroomForm.controls.mailTeacherTime.setValue(splitTime(this.classroom.mailTeacherTime));

    if(this.classroom.enabledWeekDays){
      this.classroomForm.controls.enabledWeekDays.setValue({...this.classroom.enabledWeekDays});
    }
  }

  ngOnInit(): void {
    this.initTemplateData();
    this.initForm();

    this.route.paramMap.pipe(
      map((paramMap: ParamMap) => paramMap.get('classname')),
      tap(classname => {this.classname = classname}),
      mergeMap((classname) =>
        merge(
          this.processClassroom(classname),
          this.getAllAvailableTeachers()
        )
      )
    )
    .subscribe({
      error: (err: any) => {
        if (err instanceof HttpErrorResponse)
          console.log(`${err.status} ${err.statusText}`);
        else
          console.log(err);

        this.router.navigateByUrl('/');
      }
    });
  }

  protected buildClassroom(): Classroom {
    let newClassroom: Classroom = {...this.classroomForm.value};

    const joinTimeStruct = time => `${time.hours}:${time.minutes}:${time.seconds}`;
    newClassroom.mailStudentTime = joinTimeStruct(this.classroomForm.get('mailStudentTime').value);
    newClassroom.mailTeacherTime = joinTimeStruct(this.classroomForm.get('mailTeacherTime').value);

    if (this.classroom)
      newClassroom.id = this.classroom.id;

    return newClassroom;
  }

  protected addClassroom() {
    var newClassroom = this.buildClassroom()
    this.classroomService.saveClassroom(newClassroom).subscribe(
      () => {
        this.router.navigateByUrl('/');
      },
      (err: HttpErrorResponse) => {
        if (err.status == 409) {
          this.message = 'Classroom with this id already exist';
        }
        else {
          this.message = 'A technical error has occurred, please try again later';
          console.log(`HTTP REQUEST ERROR ${err.status} ${err.statusText}`);
        }
      }
    );
  }

  protected editClassroom() {
    var newClassroom = this.buildClassroom();
    this.classroomService.editClassroom(newClassroom).subscribe(
      () => {
        this.router.navigateByUrl('/');
      },
      (err: HttpErrorResponse) => {
        if (err.status == 404) {
          this.message = 'No classroom was found with this id';
        }
        else {
          this.message = 'A technical error has occurred, please try again later';
          console.log(`HTTP REQUEST ERROR ${err.status} ${err.statusText}`);
        }
      }
    );
  }

  removeStudentFromClassroom(id: number): void {
    let user: User = this.classroom.students.filter((user: User) => user.id === id).pop();

    // Copy and update existing user
    let newUser: User = {...user};
    newUser.classroomName = null;

    if (confirm('Weet je zeker dat je deze student uit de klas wilt verwijderen?')) {
      // Do update API call
      this.userService.updateUser(newUser).subscribe(
        () => {
          let index = this.classroom.students.indexOf(user)
          if (index > -1)
            this.classroom.students.splice(index, 1);

          // Update mat-table
          this.table.renderRows();
        }
      );
    }
  }

  onHoursChange(event, controlName: string) {
    const hours = Number(event.target.value);
    const wrapHours = ((hours%24)+24) % 24;
    this.classroomForm.get(controlName).get('hours').setValue(wrapHours.toString().padStart(2,'0'));
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.classroomForm.valid) {
      if (this.classroom){
        this.editClassroom();
      }
      else {
        this.addClassroom();
      }
    }
  }

  getHelp(components: String[]): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "450px";
    dialogConfig.height = "600px";
    dialogConfig.data = {'components': components};
    this.dialog.open(HelpWindowComponent, dialogConfig);
  }
}
