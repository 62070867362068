<h2> {{ day.date |  date: 'EEEE dd, MMMM' }} </h2>
<p>de volgendede studenten zijn al ingepland deze dag:</p>
<ul>
  <li *ngFor="let element of day.userButtons">{{ element.user.name }}</li>
</ul>
<form class="pt-2 pb-2" (ngSubmit)="onSubmit()" [formGroup]="editScheduleForm">

  <div *ngFor="let field of editScheduleMeta">
    <div class="form-group row">
      <label class="col-form-label {{ labelCol }}" [for]="field.id">{{ field.labelText }}</label>

      <select *ngIf="field.id == 'oldStudent'"
              class="form-control {{ inputCol }}" [id]="field.id" [formControlName]="field.controlName" >

        <option [value]="emptyUser.id">{{ emptyUser.name }}</option>
        <option *ngFor="let student of selectedStudents" [value]="student.id">{{ student.name }}</option>
      </select>

      <select *ngIf="field.id == 'newStudent'"
              class="form-control {{ inputCol }}" [id]="field.id" [formControlName]="field.controlName">

        <option [value]="emptyUser.id">{{ emptyUser.name }}</option>
        <option *ngFor="let student of unselectedStudents" [value]="student.id">{{ student.name }}</option>
      </select>

    </div>

    <div *ngIf="submitted && editScheduleForm.controls[field.controlName].errors"
         class="alert alert-danger">

      <div *ngIf="editScheduleForm.controls[field.controlName].errors.required">
        Dit veld is vereist.
      </div>

    </div>

    <div *ngIf="submitted && editScheduleForm.errors?.classFull && field.controlName === 'newStudent'"
         class="alert alert-danger">
      Geen plek voor een student zonder een student te verwijderen.
    </div>

    <div *ngIf="submitted && editScheduleForm.errors?.available && ['oldStudent','newStudent'].includes(field.controlName)"
         class="alert alert-danger">
      Selecteer minstens 1 student.
    </div>



  </div>

  <div class="text-center">
    <button type="submit" class="btn btn-outline-primary" [disabled]="dialogRef.disableClose">Opslaan</button>
  </div>

</form>

<div *ngIf="submitted && message" class="alert alert-danger">
  {{ message }}
</div>
