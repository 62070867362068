<div class="d-flex justify-content-center">
  <ngb-datepicker
    #dp displayMonths="2" [minDate]="minDate"
    [dayTemplate]="scheduleDay" (navigate)="onNavigate($event)"
    (dateSelect)="onDateSelect($event)"
  >
  </ngb-datepicker>

  <ng-template
    #scheduleDay let-date="date"
    let-focused="focused" let-selected="selected"
    let-disabled="disabled" let-currentMonth="currentMonth"
  >
    <div
      class="schedule-day"
      [title]="getAvailabilityDescription(date)"
      [class.btn-light]="!selected && !isOverwritten(date) && isWeekdayPhysical(date) && !isRange(date)"
      [class.btn-primary]="selected || isRange(date)"
      [class.btn-success]="isPhysical(date)"
      [class.btn-info]="isDigital(date)"
      [class.btn-danger]="isUnavailable(date)"
      [class.btn-secondary]="!isOverwritten(date) && isWeekdayDigital(date)"
      [class.btn-warning]="!isOverwritten(date) && isWeekdayUnavailable(date)"
      [class.text-muted]="!selected && (date.month !== currentMonth || disabled)"
      [class.outside]="!selected && (date.month !== currentMonth || disabled)"
      [class.active]="selected || isRangeBorder(date)"
      [class.in-range]="inRange(date)"
    >
      {{ date.day }}
    </div>
  </ng-template>
</div>

<div class="d-flex-wrap justify-content-center">
  <button
    type="button" class="btn btn-outline-primary" [disabled]="!overwriteEnabled"
    (click)="onScheduleEdit(availability.Physical)"
  >
    Inroosteren
  </button>
  <button *ngIf="onlinePresence"
    type="button" class="btn btn-outline-primary" [disabled]="!overwriteEnabled"
    (click)="onScheduleEdit(availability.Digital)"
  >
    Online aanwezig
  </button>
  <button
    type="button" class="btn btn-outline-primary" [disabled]="!overwriteEnabled"
    (click)="onScheduleEdit(availability.Unavailable)"
  >
    Uitroosteren
  </button>
  <button type="button" class="btn btn-outline-primary" [disabled]="!overwriteEnabled" (click)="onScheduleRemoval()">
    Verwijderen
  </button>
  <button type="button" class="btn btn-outline-primary" [disabled]="!overwriteEnabled" (click)="onScheduleRefresh()">
    Verversen
  </button>
  <button
    type="button" class="btn" (click)="toggleSelectionMode()"
    [class.btn-outline-primary]="!rangeModeEnabled"
    [class.btn-outline-success]="rangeModeEnabled"
  >
    {{ (rangeModeEnabled ? 'Bereik' : 'Enkel') + 'modus' }}
  </button>
</div>
