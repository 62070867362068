<div class="pt-1">

  <div class="text-center">
    <button type="button" mat-fab color="primary">
      <img class="profile-picture" *ngIf="previewImage" [src]="previewImage">
      <div *ngIf="!previewImage">{{initials}}</div>
    </button>
  </div>

  <div *ngIf="currentUser.id === userId" class="text-left row pt-2 pb-2">
    <label for="profilePicture" class="col-form-label col-md-4">Profielafbeelding:</label>
    <input type="file" id="profilePicture" class="form-control col-md-7" (change)="onFileChanged($event)">

    <ng-container *ngIf="imageChangedEvent">
      <span class="col-form-label col-md-4">Afbeelding bewerken:</span>
      <div class="border col-md-7">
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="1 / 1"
          format="png"
          (imageCropped)="imageCropped($event)"
          (loadImageFailed)="loadImageFailed()">
        </image-cropper>
      </div>
    </ng-container>
  </div>

  <div class="row">
    <div class='col-md-4 text-left ml-0'>Afbeelding:</div>
    <div class='col-md-7 offset-md-0'>
      <button *ngIf="currentUser.id === userId" type="button" class="btn btn-outline-primary ml-0"
            (click)="onSaveProfilePicture()">
        Opslaan
      </button>
      <button *ngIf="userId && currentProfilePictureImage" type="button" class="btn btn-outline-danger"
            (click)="onDeleteProfilePicture()">
       verwijderen
      </button>
    </div>
  </div>

</div>